import posthog from 'posthog-js'
import { mixpanelEvents, EventBus, Events } from '@/events';
import { flattenObject } from '@/helpers/object-transformers';

// Events that must be throttled ("SENSITIVE" events)
// Keys correspond to the events in mixpanelEvents.SENSITIVE
const SENSITIVE_STOP_DURATIONS = {
    KEYWORDS_LISTED_EVENT: 20000,
    KEYWORDS_SUGGESTED_ADDED_EVENT: 20000,
    KEYWORDS_SUGGESTED_ADDED_ALL_EVENT: 20000
};

export function init() {
    if (!process.env.POSTHOG_KEY) {
        return;
    }

    posthog.init(process.env.POSTHOG_KEY, {
        api_host: process.env.POSTHOG_HOST,
        capture_pageview: false,
    });

    Vue.prototype.$posthog = posthog;

    Object.keys(mixpanelEvents.NOT_SENSITIVE).forEach(key => {
        EventBus.on(Events[key], data => {
            posthog.capture(mixpanelEvents.NOT_SENSITIVE[key], flattenObject(data));
        });
    });

    let stopEvent = [];

    Object.keys(mixpanelEvents.SENSITIVE).forEach(key => {
        EventBus.on(Events[key], data => {
            // If this event is currently "stopped" (throttled), do nothing
            if (stopEvent[key]) {
                return;
            }

            posthog.capture(mixpanelEvents.SENSITIVE[key], flattenObject(data));

            // If this event has a stop duration, throttle it
            const stopDuration = SENSITIVE_STOP_DURATIONS[key];
            if (stopDuration) {
                stopEvent[key] = true;
                setTimeout(() => {
                    delete stopEvent[key];
                }, stopDuration);
            }
        });
    });
}
