import { DOWNGRADE_PLAN, UPGRADE_PLAN, SUBSCRIPTION_CHANGE_HEADER } from '@/constants';

export default (i18n, toastr) => {
    return res => {
        if (!res) return;
        
        const {config, data} = res;

        if (config.method === 'put' && config.url.includes('/billing/subscription/plan')) {
            const planText = data.data.attributes.package > 1000 ? 'your': `to ${data.data.attributes.package_info.title.toLowerCase()}`;
            let message = 'plan-update-message';
           
            switch (config.headers[SUBSCRIPTION_CHANGE_HEADER]) {
                case UPGRADE_PLAN:
                    message = 'plan-upgrade-message';
                    break;
                case DOWNGRADE_PLAN:
                    message = 'plan-downgrade-message';
                    break;
            }

            if(config.params?.trial_period) {
                message = 'trial-message';
            }

            toastr.s(i18n.t(message, { plan: planText }));
        }

        if (config.method === 'post' && config.url.includes('/billing/card')) {
            toastr.s(i18n.t('billing-card-add-success'));
        }

        if (config.method === 'post' && config.url.includes('/billing-details')) {
            toastr.s(i18n.t('saved-changes-msg'));
        }

        if (config.method === 'put' && config.url.includes('/apply-coupon')) {
            toastr.s(i18n.t('coupon-applied-msg'));
        }

        return res;
    };
};