export default (i18n, toastr) => {
    return res => {
        if (!res) return;

        const {config} = res;

        if (config.method === 'put' && (config.url.includes('/self') )) {
            toastr.s(i18n.t('saved-changes-msg'));
        }

        if (config.method === 'get' && (config.url.includes('users/email') )) {
            toastr.s(i18n.t('email-seccessfully-sent'));
        }

        if (config.method === 'post' && (config.url.includes('/reset-on-demand-refresh-limit') )) {
            toastr.s(i18n.t('refresh-limit-success'), i18n.t('payment-successful'));
        }

        return res;
    };
};
