export default (i18n, toastr) => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config, response } = error;

        if (!config || !response) {
            return Promise.reject(error);
        }

        if (config.method === 'get' && config.url.includes('/check-email')) {
            toastr.e(i18n.t('check-email-error'));
        }

        if (config.method === 'post' && config.url.includes('/token')) {
            toastr.e(i18n.t('unexpected-error'));
        }

        return Promise.reject(error);
    };
};
