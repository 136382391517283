export default (i18n, toastr) => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config } = error;

        if (!config) {
            return Promise.reject(error);
        }

        if (config.method === 'patch' && config.url.includes('/user/invoices/options')) {
            toastr.e(i18n.t('invoice-options-save-error-msg'));
        }

        return Promise.reject(error);
    };
};