export default (i18n, toastr) => {
    return res => {
        if (!res) return;
        
        const {config, data} = res;

        if (config.method === 'get' && config.url.includes('/token')) {
            toastr.s(i18n.t('success-password-refreshed'));
        }


        if (config.method === 'post' && config.url.includes('/token')) {
            if (data.data && data.data.meta && data.data.meta.code == 115) {
                toastr.e(i18n.t('password-link-expired'));
            } else {
                toastr.s(i18n.t('password-updated'));
            }
        }

        return res;
    };
};
