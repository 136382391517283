export default (i18n, toastr) => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config, response } = error;

        if (!config || !response) {
            return Promise.reject(error);
        }

        const { errors } = response.data;

        if (config.method === 'put' && config.url.includes('/billing/subscription/resume')) {
            toastr.e(i18n.t('billing-subscription-resume-error'));
        }

        else if (config.method === 'put' && config.url.includes('/billing/subscription/plan')) {
            if (response && response.data && response.data.errors && response.data.errors[0]) {
                toastr.e(error.response.data.errors[0].message);
            } else {
                toastr.e(i18n.t('update-plan-error'));
            }
        }


        if (config.method === 'post' && config.url.includes('/billing-details')) {
            errors?.forEach(element => {
                if (element.message) {
                    toastr.e(element.message);
                }
                if (element.detail) {
                    toastr.e(element.detail);
                }
            });
        }
        if (config.method === 'post' && config.url.includes('/billing/subscription')) {
            toastr.e(i18n.t('update-plan-error'));
        }

        if (config.method === 'post' && config.url.includes('/billing/card')) {
            toastr.e(i18n.t('billing-update-card-error-msg'));
        }

        return Promise.reject(error);
    };
};