<template>
    <div class="block-wrapper">
        <div class="header-wrapper">
            <chart-header
                :title="$t('available-chart-titles.top_pages')"
                :toolTip="$t('top_pages-tooltip')"
                showTimeframe
                isHidable
                @onHide="onHide"
                :date="date"
                @onDateSelect="onDateSelect"
                :isHideDisabled="isLoading"
                exactDate
                :defaultKey="comparedDate"
            />
        </div>
        <div class="table-content">
            <loader v-if="getProjectTopPages.loading" />
            <div v-else>
                <custom-table
                    :config="config"
                    :items="getProjectTopPages.data || []"
                    :downloadable="false"
                    :copyable="false"
                    withBorder
                />
            </div>
        </div>
    </div>
</template>
 
<script>
    import { mapGetters, mapActions } from "vuex";
    import CustomTable from 'components/ui-elements/CustomTable';
    import { topPagesTableConfig } from './config/tablesConfig'
    import ChartBlockHeader from "./ChartBlockHeader.vue";
    import { exactDatePickerList } from '@/components/date-range-picker/datePickerList'

    export default {
        name: 'TopPagesBlock',
        components: {
            'custom-table': CustomTable,
            'chart-header': ChartBlockHeader
        },
        data(){
            return {
                config: topPagesTableConfig,
                date: null,
                isLoading: false,
                comparedDate: null,
                fetched: false
            }
        },
        watch: {
            getActiveTag: function() {
                this.fetchData();
            },
        },
        mounted(){
            if(!this.fetched && this.getCurrentProject?.project_id){
                this.fetchData();
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentProject',
                'getProjectTopPages',
                'getActiveTag',
                'getProjectOverviewOptionItem'
            ]),
        },
        methods: {
            ...mapActions([
                'setProjectOverviewOptionIsVisible',
                'fetchProjectTopPagesChartData',
                'updateProjectTimeFrame'
            ]),
            onHide() {
                this.isLoading = true;
                this.setProjectOverviewOptionIsVisible({ 
                    projectId: this.getCurrentProject.id,
                    option: "top_pages",
                    value: false 
                }).finally(()=>{
                    this.isLoading = false;
                });
            },
            onDateSelect(date, key) {
                this.updateProjectTimeFrame({
                    projectId: this.getCurrentProject.id,
                    option: 'top_pages',
                    value: {
                        date: date
                    },
                    interval: {
                        compared_date: key
                    }
                })
                this.fetchData(date, key);
            },
            fetchData(dateValue, key){
                if (!this.getCurrentProject) return;
                this.fetched = true;
                const { compared_date } = this.getProjectOverviewOptionItem('top_pages');

                let intervalDate = null;

                if(key && key !== this.comparedDate){
                    this.date = null;
                    this.comparedDate = key;
                } else {
                    this.comparedDate = compared_date;
                    if(!dateValue){
                        if(!this.date && compared_date){
                            this.comparedDate = compared_date;
                            intervalDate = exactDatePickerList[compared_date].value;
                        } else {
                            this.date = null;
                        }
                    } else {
                        this.date = dateValue;
                    }
                }
                const date = intervalDate ? intervalDate : this.date;

                const tagId = this.$route.query?.tag;
                this.fetchProjectTopPagesChartData({ 
                    projectId: this.getCurrentProject.project_id, 
                    tagId,
                    comparedDate: date || dateValue,
                });
            },
        }
    };
</script>

<style lang="scss" scoped>
    .block-wrapper {
        width: 100%;

        .header-wrapper {
            margin-bottom: 10px;
        }

        .table-content {
            width: 100%;
            height: 100%;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            & > * {
                width: 100%;
            }
        }

        /deep/ .icon-arrow_4 {
            margin-right: 2px;
            font-size: 10px;
            color: kw-color(kw-green, 1);

            &.icon-rotate-down{
                color: kw-color(kw-red, 1);
            }
        }
        
        /deep/ .custom-table {
            min-height: 250px;

            .custom-table__section_head {
                .custom-table__row {
                    text-transform: capitalize;
                }
            }
        
            .custom-table__cell {
                justify-content: flex-end;

                &:nth-child(1) {
                    flex: 1;
                    justify-content: flex-start;
                }
                &:nth-child(3) {
                    text-align: right;
                }
            }

            .custom-table__section_body {
                .custom-table__cell {
                    &:nth-child(2){
                        font-weight: 700;
                    }
                }
            }
        }
    }
</style>
