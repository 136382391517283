export default (i18n, toastr) => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config } = error;

        if (!config) {
            return Promise.reject(error);
        }

        if (config.method === 'get' && config.url.includes('/whitelabel-domain')) {
            toastr.e(i18n.t('whitelabel-domain-fetch-error'));
        }

        return Promise.reject(error);
    };
};
