import {mapGetters} from 'vuex';
import {parseUrl} from '@/helpers/url';

const emailRegExp = /^[A-Za-z0-9'!$#%’+^&*/=?`{|}~]{1}[A-Za-z0-9'.\-_!$#%’+^&*/=?`{|}~]{1,63}@[A-Za-z0-9.\-_\\!$#%’+^&*/=?`{|}~,]{1,242}\.[A-Za-z]{1,11}$/;
// const urlRegExp = /\b((http[s]?|ftp):\/\/)?([\w|\W])+\.([\w|\W]+)$/i;
const urlRegExp = /^(http[s]?:\/\/)?([\p{L}\d\-]+(?:\.[\p{L}\d\-]+)+)(\/[\p{L}\d\-._~:/?#[\]@!$&'()*+,;=%]*)?$/u
const urlProtocolImportantRegExp = /\b((http[s]?|ftp):\/\/)+([\w|\W])+\.([\w|\W]+)$/i;
// const urlEmptyAllowedRegExp = /^$|(\b((http[s]?|ftp):\/\/)?([\w|\W])+\.([\w|\W]+))$/i;
const urlEmptyAllowedRegExp = /^$|^(http[s]?:\/\/)?([\p{L}\d\-]+(?:\.[\p{L}\d\-]+)+)(\/[\p{L}\d\-._~:/?#[\]@!$&'()*+,;=%]*)?$/u
const youtubeRegExp = /^[A-Za-z0-9_-]{11}$/;
const metricsRegExp = /^[1-9][0-9]?$|^100$|^p[1-9][0-9]?$/;
const accountNameRegExp = /^(-|')|--|''|(-|')$/;
const accountPhoneRegExp = /^[0-9]{8,12}$/;
const projectName = /^[\.]|[\%\'"\\]/;

export default {
    computed: {
        ...mapGetters(['getProjectByName']),
    },
    methods: {
        singleEmailValidator (email) {
            if (!emailRegExp.test(email)) {
                return {
                    text: this.$t('invalid-email'),
                    type: 'error',
                };
            }

            return {};
        },
        multipleEmailsValidator (emails) {
            const errors = [];

            if (emails == '') return errors;

            const emailsArray = emails.split(',');

            emailsArray.forEach(email => {
                email = email.trim();

                if (!emailRegExp.test(email)) {
                    errors.push(`Email "${email}" is invalid`);
                }
            });

            return errors;
        },
        minLength (text, length, name) {
            let errors = [];
            if (text.length < length) {
                errors.push(` "${name}" has to be longer then ${length}`);
            }
            return errors;
        },
        companyNameValidator (text) {
            if (text.length > 50) {
                return {
                    text: this.$t('company-name-max-error'),
                    type: 'error',
                };
            }

            return {};
        },
        billingCityValidator (text) {

            if (!text || !text.length) {
                return {
                    text: this.$t('cant-be-empty'),
                    type: 'error',
                };
            }

            if (text.length > 255) {
                return {
                    text: this.$t('city-name-max-error'),
                    type: 'error',
                };
            }

            return {};
        },
        postalCodeValidator (text) {

            if (!text || !text.length) {
                return {
                    text: this.$t('cant-be-empty'),
                    type: 'error',
                };
            }

            return {};
        },

        companyDescriptionValidator (text) {
            if (text.length > 1000) {
                return {
                    text: this.$t('description-name-max-error'),
                    type: 'error',
                };
            }

            return {};
        },
        urlValidatorOrEmpty (url) {
            url = url.replace(/^https:\/\/|^http:\/\//, '');//remove protocol from url
            if (!urlEmptyAllowedRegExp.test(url)) {
                return {
                    text: this.$t('website-url-incorrect'),
                    type: 'warning',
                };
            }

            return {};
        },
        urlValidator (url) {
            if (!url) {
                return {
                    text: this.$t('please-enter-url'),
                    type: 'error',
                };
            } else if (!urlRegExp.test(url)) {
                return {
                    text: this.$t('website-url-incorrect'),
                    type: 'warning',
                };
            } else {
                try {
                    parseUrl(url);
                } catch (error) {
                    return {
                        text: this.$t('website-url-incorrect'),
                        type: 'warning',
                    };
                }

                return {};
            }
        },
        youtubeIdValidator (id) {
            if (!id) {
                return {
                    text: this.$t('please-enter-url'),
                    type: 'error',
                };
            } else if (!youtubeRegExp.test(id)) {
                return {
                    text: this.$t('video-id-msg'),
                    type: 'warning',
                };
            } else {
                return {};
            }
        },
        bulkKeywordsValidator (keywords) {
            if (keywords.length === 1 && !keywords[0]) {
                return {
                    text: this.$t('example-url-and-keyword'),
                    type: 'error',
                };
            } else {
                let errors = {};

                for (let i = 0; i < keywords.length; i++) {
                    if (!keywords[i].length) {
                        continue;
                    }

                    if (!keywords[i].includes(':')) {
                        errors = {
                            text: `${this.$t('correct-format-msg')}: ${keywords[i]}`,
                            type: 'error',
                        };

                        break;
                    }

                    errors = this.urlValidator(keywords[i].split(':')[0]);

                    if (errors.text) {
                        break;
                    }

                    if (!keywords[i].split(':')[1] || /^\s+$/.test(keywords[i].split(':')[1])) {
                        errors = {
                            text: this.$t('enter-least-one-keyword'),
                            type: 'error',
                        };

                        break;
                    }

                    if (keywords[i].split(':').length > 2 && !keywords[i].split(':')[2] || keywords[i].split(':')[2] === '') {
                        errors = {
                            text: this.$t('at-least-one-tag-msg'),
                            type: 'error',
                        };

                        break;
                    }
                    if (keywords[i].split(':').length > 3) {
                        errors = {
                            text: this.$t('invalidate-bulk-string'),
                            type: 'error',
                        };

                        break;
                    }
                }

                return errors;
            }
        },
        metricsRangeValidator (allRanges) {
            const invalidRanges = [];
            allRanges.forEach((el, i) => {
                // test valid values for metrics ranges
                if (!metricsRegExp.test(el)) invalidRanges.push(i);
            });

            if (invalidRanges.length) {
                return {
                    indexes: invalidRanges,
                    text: this.$t('invalid-value'),
                    type: 'error',
                };
            }

            // find duplicates using an object
            const duplicates = {};
            allRanges.forEach((el, i) => {
                if (duplicates.hasOwnProperty(el)) {
                    duplicates[el].push(i);
                } else if (allRanges.lastIndexOf(el) !== i) {
                    duplicates[el] = [i];
                }
            });

            if (!_.isEmpty(duplicates)) {
                return {
                    // unite object with doubles to flat array
                    indexes: _.union(...Object.values(duplicates)),
                    text: this.$t('same-values'),
                    type: 'duplicates-error',
                };
            }

            return {};
        },
        passwordValidator (password) {
            if (password.length < 8 || password.length > 100) {
                return {
                    text: this.$t('password-validation'),
                    type: 'error',
                };
            }

            return {};
        },
        oldPasswordValidator (password) {
            if (!password.length) {
                return {
                    text: this.$t('old-password-validation'),
                    type: 'error',
                };
            }

            return {};
        },
        projectPasswordValidator (password) {
            if (password.length < 3 || password.length > 100) {
                return {
                    text: this.$t('password-validation'),
                    type: 'error',
                };
            }

            return {};
        },
        projectNameValidator (name, parent, id) {
            name = name.trim();
            const type = parent ? 'group' : 'project';
            if (projectName.test(name)) {
                return {
                    text: this.$t('invalid-project-name'),
                    type: 'error',
                };
            }
            if (!name || !name.length) {
                return {
                    text: this.$t(`empty-${type}-name`),
                    type: 'error',
                };
            }

            if (name.length > 50) {
                return {
                    text: this.$t('project-name-max-error'),
                    type: 'error',
                };
            }
            if (this.getProjectByName({name, parent}) && this.getProjectByName({name, parent}).project.id !== id) {
                return {
                    text: this.$t(`${type}-already-exist`, { name }),
                    type: 'error',
                };
            }

            return {};
        },
        accountNameValidator (name) {
            if (name.trim().length > 50) {
                return {
                    text: this.$t('long-name-error'),
                    type: 'error',
                };
            }

            if (accountNameRegExp.test(name)) {
                return {
                    text: this.$t('invalid-username'),
                    type: 'error',
                };
            }

            return {};
        },
        accountPhoneValidator (phone) {
            if (!phone || !phone.length) {
                return {
                    text: this.$t('cant-be-empty'),
                    type: 'error',
                };
            }

            if (!phone.match(accountPhoneRegExp)) {
                return {
                    text: this.$t('only-numbers'),
                    type: 'error',
                };
            }

            if (phone && (phone.length < 8 || phone.length > 12)) {
                return {
                    text: this.$t('phone-min-max'),
                    type: 'error',
                };
            }

            return {};
        },
        reportsSettingsInputValidator (value, label, max, type) {
            if (type && type === 'emailsubject' && (!value || !value.length)) {
                return {
                    text: this.$t('value-cant-be-empty', {value: label}),
                    type: 'error',
                };
            }

            if (value && value.length > max) {
                return {
                    text: this.$t('long-value-error', {limit: max, value: label}),
                    type: 'error',
                };
            }

            if (accountNameRegExp.test(value)) {
                return {
                    text: this.$t('invalid-value-of', {value: label}),
                    type: 'error',
                };
            }

            return {};
        },
        companyUrlValidator (url, label) {
            if (!url || !url.length) {
                return {};
            }

            if (!urlRegExp.test(url)) {
                return {
                    text: this.$t('invalid', {value: label}),
                    type: 'error',
                };
            }

            return {};
        },
        companyLogoUrlValidator (url, label) {
            if (!url || !url.length) {
                return {};
            }

            if (!urlProtocolImportantRegExp.test(url)) {
                return {
                    text: this.$t('invalid', {value: label}),
                    type: 'error',
                };
            }

            if (url.includes('fbcdn')) {
                return {
                    text: this.$t('unable-load-image'),
                    type: 'error',
                };
            }

            return {};
        },
    }
}
