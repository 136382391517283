export default (i18n, toastr) => res => {
    if (!res) return;

    const { config } = res;

    if (
        config.method === 'post' &&
        config.url.includes('/import') &&
        !config.url.includes('import/')
    ) {
        toastr.s(i18n.t('file-uploaded-successfully'));
    } else if (config.method === 'put' && config.url.includes('/map-headings')) {
        toastr.s(i18n.t('file-validated-successfully'));
    } else if (config.method === 'put' && config.url.includes('/update')) {
        toastr.s(i18n.t('row-validated-successfully'));
    } else if (config.method === 'post' && config.url.includes('/accept')) {
        toastr.s(i18n.t('importing-process-started'));
    }

    return res;
};
