import router from '@/routes';
import { logout } from '@/helpers/service';
import * as Sentry from '@sentry/browser';
import { cookieGetters } from '@/helpers/cookieManager';

const urlsForRedirectToServerErrorPage = ['/self', '/groups/active'];

export default (i18n, toastr) => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config, response } = error;

        if(!config) {
            Sentry.captureException(error, {
                tags: {
                    errorType: 'Missing Config'
                },
                extra: {
                  errorData: JSON.stringify(error || {}),
                  userEmail: userProfile?.email || 'no email in cookie'
                },
            });
            return Promise.reject(error);
        }

        if(config.url.includes('/event-tracking/mixpanel')){
            return;
        }
        
        if (!response) {
            if (process.env.NODE_ENV !== 'production') {
                console.dir(error);
            }

            toastr.e(i18n.t('unexpected-error'));
            return Promise.reject(error);
        }

        const { data, status } = response;

        if (status === 400 && config.url.includes('/import') && config.url.includes('/update')) {
            return Promise.reject(error);
        }

        if (
            status === 401 &&
            router.currentRoute.name !== 'shareViewkey' &&
            router.currentRoute.name !== 'shareViewkeyWithHash' &&
            router.currentRoute.name !== 'shareOfVoice' &&
            router.currentRoute.name !== 'shareOfVoiceWithHash' &&
            router.currentRoute.name !== 'shareKeywordsTable' &&
            router.currentRoute.name !== 'shareKeywordsTableWithHash'
        ) {
            logout();
            return Promise.reject(error);
        }

        const { errors = [] } = data || {};
        const userProfile = cookieGetters.getUserProfile();

        if (errors.length === 0 || status >= 500) {
            toastr.e(i18n.t('unexpected-server-error'));

            if (process.env.NODE_ENV !== 'production') {
                console.warn('Status: ' + status);
                console.log(errors[0]);
            } else {
                Sentry.captureException(error, {
                    tags: {
                        errorType: 'Server Error'
                    },
                    extra: {
                      url: config.url,
                      status: status,
                      errorData: JSON.stringify(data || {}),
                      errorsLength: errors.length,
                      userEmail: userProfile?.email || 'no email in cookie'
                    },
                });
            }

            if (config.method === 'get' && urlsForRedirectToServerErrorPage.some(url => config.url.includes(url))) {
                router.push({ name: 'ServerError' });
            }
        }

        return Promise.reject(error);
    };
};
