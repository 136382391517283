<template>
     <div class="block-wrapper">
        <div class="header-wrapper">
            <chart-header
                :title="$t('available-chart-titles.keywords_by_intent')"
                :toolTip="$t('keywords_by_intent-tooltip')"
                isHidable
                @onHide="onHide"
                :isHideDisabled="isLoading"
            />
        </div>
        <div class="table-content">
            <loader v-if="getProjectKeywordsByIntent.loading" />

            <custom-table
                v-else
                :config="config"
                :items="getProjectKeywordsByIntent.data || []"
                :downloadable="false"
                :showChart="true"
                :copyable="false"
                withBorder
            />
        </div>
    </div>
</template>
 
<script>
    import { mapGetters, mapActions } from "vuex";
    import CustomTable from 'components/ui-elements/CustomTable';
    import { keywordsByIntentTableConfig } from './config/tablesConfig'
    import ChartBlockHeader from "./ChartBlockHeader.vue";

    export default {
        name: 'KeywordsByIntentBlock',
        components: {
            'custom-table': CustomTable,
            'chart-header': ChartBlockHeader
        },
        data(){
            return {
                config: keywordsByIntentTableConfig,
                isLoading: false,
                fetched: false,
            }
        },
        watch: {
            getActiveTag: function() {
                this.fetchData();
            },
        },
        mounted(){
            if(!this.fetched && this.getCurrentProject?.project_id){
                this.fetchData();
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentProject',
                'getProjectKeywordsByIntent',
                'getActiveTag',
            ]),
        },
        methods: {
            ...mapActions([
                'setProjectOverviewOptionIsVisible',
                'fetchProjectKeywordsByIntent'
            ]),
            onHide() {
                this.isLoading = true;
                this.setProjectOverviewOptionIsVisible({ 
                    projectId: this.getCurrentProject.id,
                    option: "keywords_by_intent",
                    value: false 
                }).finally(()=>{
                    this.isLoading = false;
                });
            },
            fetchData(){
                if (!this.getCurrentProject) return;
                this.fetched = true;
                const tagId = this.$route.query?.tag;
                this.fetchProjectKeywordsByIntent({ projectId: this.getCurrentProject.project_id, tagId });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .block-wrapper {
        width: 100%;
        .header-wrapper {
            margin-bottom: 10px;
        }

        .table-content {
            width: 100%;
            height: 100%;
            min-height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            & > * {
                width: 100%;
            }
        }
        
        /deep/ .custom-table {
            min-height: 250px;
            .custom-table__cell {
                &:nth-child(2) {
                    flex: 1;
                }
                &:nth-child(4) {
                    justify-content: flex-end;
                }
            }
            .custom-table__section_body {
                .custom-table__cell {
                    &:nth-child(1) {
                        font-weight: 500;
                    }
                    &:nth-child(3),
                    &:nth-child(4) {
                        font-weight: 700;
                    }
                }
            }
        }
    }
</style>
 