import { saveLoginData } from '@/helpers/service';
import apiFactory from '@/api';
import router from '@/routes';
import { GTM_EVENTS } from '@/constants';
import {
    SET_COUPON,
    SET_BILLING_CYCLE,
    SET_COUPON_CHECKING,
} from '@/store/mutations';
import toastr from '@/helpers/init-toastr';
import { Events, EventBus } from '@/events';

const usersApi = apiFactory.get('users');
const accountApi = apiFactory.get('account');
const legacyApi = apiFactory.get('legacy');

const auth = {
    state: {
        coupon: null,
        billingCycle: 'monthly',
        couponChecking: false,
    },
    mutations: {
        [SET_COUPON]: (state, payload) => state.coupon = payload,
        [SET_BILLING_CYCLE]: (state, payload) => state.billingCycle = payload,
        [SET_COUPON_CHECKING]: (state, payload) => state.couponChecking = payload,
    },
    getters: {
        getCoupon: state => state.coupon,
        getBillingCycle: state => state.billingCycle,
        getCouponChecking: state => state.couponChecking,
    },
    actions: {
        setCoupon({ commit }, coupon = null) {
            commit(SET_COUPON, coupon);
        },
        setBillingCycle({ commit }, billingCycle) {
            commit(SET_BILLING_CYCLE, billingCycle);
        },
        setCouponChecking({ commit }, checking = false) {
            commit(SET_COUPON_CHECKING, checking);
        },
        async logIn(context, { username, password }) {
            try {
                const res = await accountApi.login({ username, password });

                if (
                    process.env.APP_ENVIRONMENT === 'production' ||
                    process.env.APP_ENVIRONMENT === 'staging'
                ) {
                    try {
                        await legacyApi.login({ username, password });
                    } catch (err) {
                        return err
                    }
                }

                saveLoginData(res.data);
            } catch (error) {
                return error;
            }
        },
        async linkRefreshPassword(context, { email }) {
            try {
                await accountApi.refresh({ params: { email } });
            } catch (error) {
                return error;
            }
        },
        async sendToken(context, payload) {
            try {
                const res = await accountApi.sendToken(payload);
                if (res.data.data.type === 'refresh_token') {
                    router.push({ name: 'login' })
                }
            } catch (error) {
                //
            }
        },
        async register({ dispatch }, { data, params }) {
            const res = accountApi.register(data, params);

            if (!res.error) {
                /**
                 * GTM custom event successfully_signed_up
                */
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ event: GTM_EVENTS.SUCCESSFULLY_SIGNED_UP });
            }

            return res;
        },
        async checkCoupon({ dispatch }, { coupon, package_identity }) {
            let data = false;
            let res;

            try {
                dispatch('setCoupon');
                dispatch('setCouponChecking', true);
                res = await accountApi.checkCoupon(package_identity, {
                    params: {
                        coupon
                    }
                });

                data = res.data.data;

                dispatch('setCoupon', coupon);
            } catch (error) {
                if (error?.response?.data?.errors && error.response.data.errors[0]?.details) {
                    toastr.w(error.response.data.errors[0].details);
                }

                return false;
            } finally {
                dispatch('setCouponChecking', false);
            }

            return data;
        },
        async verifyEmail(context, { hash, email, recaptcha }) {
            try {
                const res = await usersApi.verifyEmail(hash, {
                    params: { email, recaptcha }
                });

                return res;
            } catch (error) {
                return false;
            }
        },
        async resendVerificationEmail(context, data) {
            try {
                const res = await usersApi.resendVerificationEmail(data);

                return res.status;
            } catch (error) {
                return false;
            }
        },
        async validateEmail(context, email) {
            const queryParameters = {
                params: {
                    email: encodeURIComponent(email)
                }
            };

            try {
                const res = await usersApi.validateEmail(queryParameters);

                return JSON.parse(res.data.data);
            } catch (error) {
                return false;
            }
        },
        async onboardUser(context, data) {
            try {
                const res = await usersApi.onboardUser(data);
                EventBus.emit(Events.USER_ONBOARD, data);
               return Promise.resolve(res);
            } catch (error) {
               return Promise.reject(error);
            }
        },
        async socialSignIn(context, { provider, data, trial_period }) {
            try {
                const res = await accountApi.socialSignIn(
                    provider,
                    {
                        ...data,
                        include: 'settings,subscriptions',
                    },
                    trial_period
                );

                if (res.data.errors) {
                    return res.data.errors;
                }

                saveLoginData(res.data);
            } catch (error) {
                return error;
            }
        },
    }
};

export default auth;
