export default (i18n, toastr) => {
    return error => {
        if (!error?.config) return Promise.reject(i18n.t('unexpected-error'));

        const { config } = error;

        if (config.method === 'post' && config.url.includes('/notes')) {
            toastr.e(i18n.t('notes-add-error'));
        }

        if (config.method === 'delete' && config.url.includes('/notes/')) {
            toastr.e(i18n.t('notes-delete-error'));
        }

        if (config.method === 'put' && config.url.includes('/notes/')) {
            toastr.e(i18n.t('notes-update-error'));
        }

        return Promise.reject(error);
    };
};