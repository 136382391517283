export default (i18n, toastr) => {
    return res => {
        if (!res) return;

        const {config} = res;

        if (config.method === 'put' && (config.url.includes('/email-notifications') )) {
            toastr.s(i18n.t('email-notification-settings-updated'));
        }

        return res;
    };
};
