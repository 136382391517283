<template>
    <div v-click-outside="closeDropdown" class="options">
        <kw-button button-type="secondary" prepend-icon-class="kw kw-grid" @click="toggleDropdown" />

        <custom-dropdown-body ref="optionsDropdown" position="bottom-right">
            <div class="options-list">
                <div v-if="isLoading" class="loader-wrapper">
                    <loader />
                </div>
                <p class="title">{{$t('available-charts')}}</p>

                <div v-for="(item, key) in optionsList" class="list-item">
                    <toggler :toggler="item.visible" @toggler_click="toggleOption(key, !item.visible)" />

                    {{ $t(`available-chart-titles.${key}`) }}
                </div>
            </div>
        </custom-dropdown-body>
    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'OverviewOptions',
        computed: {
            ...mapGetters([
                'getProjectOverviewOptions',
                'getCurrentProject',
            ]),
            optionsList(){
                const { visibility_and_estimated_traffic, ...restSettings } = this.getProjectOverviewOptions;
                return restSettings;
            }
        },
        data(){
            return {
                isLoading: false,
            }
        },
        methods: {
            ...mapActions(['setProjectOverviewOptionIsVisible']),
            toggleDropdown () {
                this.$refs.optionsDropdown.toggleDropdownOpen();
            },
            closeDropdown () {
                if(this.$refs.optionsDropdown?.getIsDropdownOpened) {
                    this.$refs.optionsDropdown.closeDropdown();
                }
            },
            toggleOption (key, value) {
                this.isLoading = true;
                this.setProjectOverviewOptionIsVisible({ projectId: this.getCurrentProject.id, option: key, value })
                .finally(()=>{
                    this.isLoading = false;
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
    .options {
        .kw-button {
            width: 36px;
            height: 36px;

            /deep/.kw {
                font-weight: 700;
            }
        }

        .options-list {
            position: relative;
            min-width: 247px;
            padding: 24px 16px;
            background: kw-color(kw-white, 1);

            .title{
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                margin-bottom: 16px;
            }

            .list-item{
                display: flex;
                gap: 8px;
                align-items: center;
                margin-bottom: 10px;
                font-family: Roboto;
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;

                &:last-child{
                    margin-bottom: 0;
                }

                .toggler {
                    position: relative;
                }
            }
            .loader-wrapper{
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(255,255,255,.5);
            }
        }
    }
</style>
