<template>
    <div
        v-if="!isViewOnlyUser"
        class="add-keywords-main-wrap"
        data-cy="add-keywords-main-wrap"
    >
        <secondary-header
            :closer="closeAddKeywords"
            data-cy="add-keyword-cancel"
            :title="$t('add-keywords')"
        />
        <div class="add-keywords-bottom-wrap">
            <div class="add-keywords-bottom">
                <tabs data-cy="add-keyword-tab" v-model="activeTab">
                    <tab :header="getOrganicTabHeader">
                        <web-domain-tab :active="activeTab === 0" />
                    </tab>
                    <tab :header="getMapsTabHeader">
                        <google-local-maps-tab :active="activeTab === 1" />
                    </tab>
                </tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MOBILE_WIDTH_RESOLUTION_PHONE } from '@/constants';
import { SET_CURRENT_GROUP_OBJECT } from '@/store/mutations';
import { tabs, tab } from 'vue-strap';
import GoogleLocalMapsTab from './components/GoogleLocalMapsTab';
import SecondaryHeader from 'components/ui-elements/SecondaryHeader';
import WebDomainTab from './components/WebDomainTab';
export default {
    name: 'AddKeywordsScreen',
    components: {
        'google-local-maps-tab': GoogleLocalMapsTab,
        'secondary-header': SecondaryHeader,
        tab: tab,
        tabs: tabs,
        'web-domain-tab': WebDomainTab,
    },
    data() {
        return {
            activeTab: 0,
        };
    },
    computed: {
        ...mapGetters(['getCurrentProject', 'isViewOnlyUser']),
        getOrganicTabHeader() {
            const text = this.$t('google-organic');
            return `<div data-cy="google-organic-tab">${text}<div>`;
        },
        getMapsTabHeader() {
            const text = this.$t('google-local-maps');
            return `<div data-cy="google-map-tab">${text}<div>`;
        },
    },
    created() {
        this.unSubscribe = this.$store.subscribe(({ type }) => {
            if (type === SET_CURRENT_GROUP_OBJECT) {
                this.fetchSuggestedRegions();
                this.fetchSuggestedUrls();
            }
        });
    },
    mounted() {
        this.fetchSuggestedRegions();
        this.fetchSuggestedUrls();

        if (window.screen.width < MOBILE_WIDTH_RESOLUTION_PHONE) {
            this.setSideBarOpened(false);
        }
    },
    beforeDestroy() {
        if (typeof this.unSubscribe === 'function') {
            this.unSubscribe();
        }
    },
    methods: {
        ...mapActions([
            'fetchSuggestedRegions',
            'fetchSuggestedUrls',
            'setSideBarOpened',
        ]),
        closeAddKeywords() {
            this.getCurrentProject
                ? this.$router.push({
                      name: 'keywordList',
                      params: this.$route.params,
                  })
                : this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss">
@import '~sass/partials/dropdowns';
@import '~sass/partials/new-nav-tabs';

.add-keywords-main-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 -26px;

    .add-keywords-web {
        .keyword-url-wrap {
            .domain-filed {
                border-radius: 0;
            }
        }
    }

    .keyword-url-wrap {
        position: relative;
        z-index: 11;

        .domain-filed {
            width: 100% !important;
            z-index: 11;
            border-radius: 0;

            &.text-input_invalid {
                border: 1px solid !important;
                border-color: kw-color(kw-red, 1) !important;
                color: kw-color(kw-red, 1) !important;
            }
        }

        .url-input-wrap {
            display: flex;
            flex-wrap: row wrap;
            border-radius: 4px;

            .text-input {
                height: 45px;
                border: unset;
                font-size: 13px;
                color: kw-color(kw-black, 1);

                &:disabled {
                    border-radius: unset;
                    border: unset !important;
                }
            }

            .protocol-type {
                display: flex;
                align-items: center;
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
                box-shadow: 0px 2px 4px rgba(31, 63, 97, 0.1);
                justify-content: center;
                height: 45px;
                color: #909399;
                font-size: 13px;
                border-right: 1px solid #dee5ec;
                max-width: 75px;
                width: 100%;
            }
        }
    }

    .keywords-seggestions-wrap {
        display: flex;
        position: relative;
        @media screen and (max-width: 959px) {
            flex-direction: column;
        }

        .header {
            display: flex;
        }

        .title-wrap {
            height: 42px;
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #dee5ec;
        }

        .keywords-title {
            background: kw-color(kw-white, 1);
            padding-left: 12px;
        }

        .keywords-wrap {
            .new-custom-dropdown-button {
                padding: unset !important;
                margin-left: 10px;

                &:after {
                    color: #828f9c;
                }
            }

            .kw-information {
                color: #828f9c;
                font-size: 12px;
                font-weight: 700;
                margin: 0 4px;
            }

            @media screen and (min-width: 960px) {
                max-width: 490px;
            }
            width: 100%;
            height: 100%;
            box-shadow: 0px 3px 10px rgba(18, 27, 41, 0.1);
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            z-index: 1;

            .keywords-teaxtarey-wrap {
                position: relative;
                background: kw-color(kw-white, 1);
                display: flex;
                padding-left: 12px;

                .line-numbers {
                    background: kw-color(kw-white, 1);
                    display: flex;
                    flex-direction: column;
                    z-index: 1;
                    padding-left: 12px;
                    padding-top: 12px;
                    padding-right: 9px;

                    .line-number {
                        font-size: 13px;
                        text-align: right;
                        color: #b9babd;
                    }
                }
            }

            .keywords-teaxtarey-wrap {
                min-height: 412px;
            }
        }

        .region-language-wrap,
        .youtube-video-id-wrap {
            display: flex;
            align-items: center;
            height: 96px;
            padding: 12px 14px;
            border-top: 1px solid #dee5ec;

            .screen__field {
                max-width: 224px;
                padding: unset;
                height: 100%;
                width: 100%;
                @media screen and (max-width: 600px) {
                    max-width: 49%;
                }
            }

            .diveder {
                display: block;
                width: 12px;
                height: 1px;
                background: #dee5ec;
                position: relative;
                top: 6px;
            }
        }

        .button-wrap {
            padding: 12px;
            border-top: 1px solid #dee5ec;

            .screen__add-button {
                width: 100%;
                margin-top: 2px;
            }
        }
    }

    .keyword-type-title {
        color: kw-color(kw-black, 1);
        font-weight: 500;
        font-size: 16px;
        margin-right: 12px;
    }

    .keyword-type-wrap {
        display: flex;
        align-items: center;
        padding-bottom: 8px;

        .text-label {
            padding-bottom: unset;
            line-height: unset;
        }
    }

    .screen__content_keyword {
        padding-top: 2px !important;

        .screen__column {
            width: 100%;
            padding-bottom: 12px;
            margin: unset;
        }
    }

    .suggestions-wrap {
        @media screen and (min-width: 960px) {
            max-width: 490px;
        }
        @media screen and (max-width: 959px) {
            border-top: 1px solid #dee5ec;
            box-shadow: unset;
        }
        width: 100%;
        box-shadow: 0px 2px 4px rgba(31, 63, 97, 0.1);
        border-radius: 4px;

        .header {
            font-size: 12px;
            color: #909399;
            height: 35px;
            display: flex;
            align-items: center;

            .ranking-title {
                margin-left: auto;
            }
        }

        i.icon-cross-round {
            cursor: pointer;
            font-size: 14px;
            margin-right: 10px;
            margin-top: -1px;
        }
    }

    .bulk-title {
        cursor: pointer;
        margin-left: 40px;

        &:focus {
            cursor: pointer;
        }
    }

    .empty-suggestions-wrap {
        background: url(~img/cactus.svg) no-repeat center 24%;
        height: 100%;
        @media screen and (max-width: 959px) {
            background: none;
        }
    }

    .empty-keywords-header {
        height: 42px;
        display: flex;
        align-items: center;
        padding-left: 12px;
        color: kw-color(kw-black, 1);
        font-size: 13px;
        background: #fcfcfc;
        font-weight: 500;
        border-bottom: 1px solid #dee5ec;
        cursor: default;
    }

    .suggestions-placeholder {
        color: #828f9c;
        font-size: 13px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 9px;
        padding-top: 9px;
        @media screen and (max-width: 960px) {
            padding-bottom: 9px;
        }

        .kw-arrow-left {
            font-size: 15px;
            margin-right: 8px;
            @media screen and (max-width: 959px) {
                transform: rotate(0);
            }
        }
    }

    *:focus {
        border: unset;
        border-bottom-color: unset;
        outline: unset;
    }

    .add-keywords-bottom {
        margin: 0 20px;
        max-width: 980px;
        width: 100%;
        z-index: 2;
        @media screen and (max-width: 959px) {
            margin: 0;
        }
    }

    .screen__title {
        font-weight: 500;
        font-size: 21px;
        margin: 0;
    }

    .add-keywords-bottom {
        .nav-tabs {
            @extend .nav-tabs_filled;
            border: unset !important;

            a {
                font-size: 13px;
                color: kw-color(kw-black, 1);
                background: unset;
                border: unset;
                padding: 0 10px;
                margin: unset;
                @media screen and (max-width: 959px) {
                    padding: 0 9px;
                }
                @media screen and (max-width: 500px) {
                    padding: 0 6px;
                }

                &:hover,
                &:focus {
                    background-color: unset;
                    border: unset;
                    border-bottom-color: unset;
                }
            }

            li {
                border: unset;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: kw-color(kw-white, 1);
                border-left: 1px solid #dee5ec;
                box-shadow: 0px 2px 2px rgba(18, 27, 41, 0.05);
                cursor: pointer;

                &.active {
                    background: kw-color(kw-blue, 1);
                    border: unset;
                    cursor: default;

                    a {
                        color: kw-color(kw-white, 1);
                    }
                }

                &:first-child {
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }
            }
        }
    }

    .keyword-type-title {
        color: kw-color(kw-black, 1);
        font-weight: 500;
        font-size: 16px;
        margin-right: 12px;
        cursor: default;
    }

    .screen__field_keyword {
        label {
            user-select: none;
            font-weight: 500;
            font-size: 13px;
            color: kw-color(kw-black, 1);
            display: flex;
            align-items: center;
            cursor: default;
        }

        .screen__textarea_keywords {
            text-indent: unset;
            padding: 5px 0;
            width: 100%;
            height: 547px !important;
            border: unset;
            padding-left: unset;
            padding-right: 12px;
            border-radius: unset;
            border: unset !important;

            &:focus {
                border: unset;
                outline: unset;
            }
        }

        .vue-tags-input {
            margin-top: 12px;
        }

        .ti-input {
            border: 1px solid #dee5ec;
            border-radius: 4px;
            @media screen and (max-width: 959px) {
                width: 100%;
            }

            .ti-tag {
                background: #f5f6f7;
                border: 1px solid #cbd6e9;
                box-sizing: border-box;
                border-radius: 4px;
                color: #265596;
            }

            input {
                width: 100%;
            }
        }

        .disabled_tag_input {
            .ti-input {
                border: 1px solid transparent;
                border-radius: 0;

                .ti-tag {
                    background: transparent;
                    border: none;
                    box-sizing: border-box;
                    border-radius: 0;
                    color: kw-color(kw-black, 1);
                    font-size: 12px;
                    font-weight: 500;
                }

                .ti-icon-close {
                    display: none;
                }
            }
        }

        .open .form-control.dropdown-toggle {
            @extend .custom-dropdown_active;
        }

        .form-control.dropdown-toggle {
            @extend .custom-dropdown;
            @media screen and (max-width: 959px) {
                width: 100%;
            }
        }

        .btn-group {
            width: 100%;
        }

        .custom-dropdown_filled {
            position: relative;

            .dropdown-menu {
                width: 100%;
            }

            .form-control.dropdown-toggle {
                color: kw-color(kw-black, 1);
            }
        }

        .dropdown-menu {
            @media screen and (max-width: 959px) {
                width: 100%;
            }
            @extend .custom-scroll;
            @extend .custom-dropdown-menu;
        }
    }
}

.add-keywords-bottom-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -25px;
    background: #f5f6f7;
    height: 100%;
    @media screen and (max-width: 959px) {
        padding: 0 10px;
    }
}

@media screen and (max-width: 959px) {
    .add-keywords-main-wrap {
        margin: 0;
    }
}
</style>
