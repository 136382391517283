export default () => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config } = error;

        if (!config) {
            return Promise.reject(error);
        }

        if (config.method === 'get' && config.url.includes('/mindshare')) {
            error.type = 'limit_exceed';
        }

        return Promise.reject(error);
    };
};
