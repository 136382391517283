<template>
    <div class="trial-expires-modal">
        <div class="body">
            <div class="title">{{ $t('trial-expires.title') }}</div>
            <div class="desc">{{ $t('trial-expires.desc') }}</div>
            <div class="packages">
                <div class="package">
                    <div class="package-title">
                        {{ $t('trial-expires.package.move.title') }}
                    </div>

                    <div class="loader-wrapper" v-if="isFreeLoading">
                        <loader size="50" />
                    </div>
                    
                    <div class="package-description">
                        <permissions isBold :current-package="freePackage" />
                    </div>

                    <kw-button
                        :disabled="isLoading"
                        class="kw-button package-button"
                        @click="moveToFree"
                        :button-text="$t('trial-expires.package.move.button')"
                    />
                </div>
                <div class="package">
                    <div class="package-title">
                        {{ $t('trial-expires.package.keep.title') }}
                    </div>

                    <div class="loader-wrapper" v-if="loading">
                        <loader size="50" />
                    </div>

                    <template v-else>
                        <div class="package-description">
                            <permissions isBold :current-package="packageData" expirePostTrial />
                        </div>
                        <kw-button
                            :disabled="isLoading"
                            class="kw-button package-button"
                            @click="openBillingPage"
                            :button-text="$t('trial-expires.package.keep.button')"
                        />

                        <div class="change-package" @click="redirectToPackages">
                            {{
                                $t('trial-expires.package.keep.choose-package')
                            }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
       
        <global-events @keyup.enter="openBillingPage" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cookieSetters } from '@/helpers/cookieManager';
import Permissions from '../billing/Permissions.vue';
import { DOWNGRADE_PLAN } from '@/constants';

export default {
    name: 'TrialExpiresModal',
    components: { 
        'permissions': Permissions,
    },
    data(){
        return {
            freePackage: null,
            isFreeLoading: false,
            isLoading: false,
        }
    },
    mounted() {
        cookieSetters.setExpireModalShown(this.getUserDataProp('email'));
        this.getFreePackage();
    },
    computed: {
        ...mapGetters(['getCurrentPlan', 'getUserDataProp']),
        packageData() {
            return {
                keywords_tracked:
                    this.getCurrentPlan?.subscription?.package ?? '',
                cycle: this.getCurrentPlan?.subscription?.cycle ?? '',
                ...this.getCurrentPlan.subscription
            };
        },
        loading() {
            return !Object.keys(this.getCurrentPlan).length;
        },
        isCurrentFree(){
            return this.getCurrentPlan.subscription.package_identity === process.env.FREE_PLAN_ID;
        }
    },
    methods: {
        ...mapActions(['fetchPackageById', 'changePlan']),
        close() {
            this.$emit('close');
        },
        redirectToPackages() {
            this.$router.push({ name: 'Pricing' });
            this.close();
        },
        openBillingPage() {
            this.$router.push({
                name: 'AccountDetails', 
                query: {id: 'r1Czk9PT8bAh4mvEQO7U2w=='} 
            });
            this.close();
        },
        moveToFree() {
            this.isLoading = true;
            this.changePlan({
                plan_id: process.env.FREE_PLAN_ID,
                billing_cycle: 'monthly',
                type: DOWNGRADE_PLAN
            }).then((res) => {
                if (res) this.close();
            }).finally(()=>{
                this.isLoading = false;
            })
        },
        async getFreePackage(){
            try {
                this.isFreeLoading = true;
                this.freePackage = await this.fetchPackageById(process.env.FREE_PLAN_ID);
            } catch (error) {
                return error;
            } finally {
                this.isFreeLoading = false;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';

.trial-expires-modal {
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    padding: 28px 0 18px;
    max-height: inherit;
    overflow-y: auto;

    .header {
        display: flex;
        justify-content: flex-end;
        padding: 9px 11px;

        /deep/ .button-closer {
            width: 9px;
            height: 9px;
        }
    }

    .body {
        .title {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #000000;
        }

        .desc {
            margin-top: 8px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #24242c;
        }

        .packages {
            margin: 16px 43px 8px;
            display: flex;
            justify-content: space-between;
            gap: 16px;

            .package {
                flex: 1;
                background: kw-color(kw-white, 1);
                border: 1px solid #afbac7;
                border-radius: 4px;
                padding-top: 24px;
                padding-bottom: 16px;

                .package-title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    color: kw-color(kw-black, 7);
                }

                .loader-wrapper {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .package-desc {
                    min-height: 20px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 20px;
                    color: kw-color(kw-black, 1);
                }

                .package-button {
                    cursor: pointer;
                    width: 135px;
                    height: 36px;
                    margin: 20px auto 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: kw-color(kw-blue, 1);
                    box-shadow: 0px 2px 5px rgba(50, 73, 98, 0.15),
                        0px 0px 1px rgba(50, 73, 98, 0.2);
                    border-radius: 4px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: kw-color(kw-white, 1);
                }

                .change-package {
                    cursor: pointer;
                    margin-top: 8px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 16px;
                    color: kw-color(kw-blue, 1);
                }

                .package-description{
                    padding-left: 42px;
                    padding-right: 20px;

                    /deep/ .permission{
                        color: kw-color(kw-black, 1);
                        padding-left: 0;
                        display: flex;

                        &:nth-child(2){
                            margin: 0;
                        }

                        & > span.kw {
                            margin: 4px 8px 0 0;
                        }
                    }
                }

                /deep/ .package-permissions,
                /deep/ .package-info-wrapper {
                    padding: 0;
                    margin: 0;
                    margin-top: 16px;
                    text-align: left;
                }
            }
        }
    }
}
</style>
