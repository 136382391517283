<template>
    <div
        class="addons-settings"
        data-cy="addons-settings"
    >
        <div class="addons-settings__table">
            <div class="addons-settings__table-header">
                <div
                    v-for="header in sharedAccessTableHeaders"
                    :key="header.title"
                    class="addons-settings__header-cell"
                >
                    <span v-text="header.title" />
                    <span
                        v-if="header.tooltip"
                        class="kw kw-information"
                        @mouseover="tooltip({text: header.tooltip, event: $event})"
                    />
                </div>
            </div>
            <template v-for="(user, i) in users">
                <div
                    v-if="isRowVisible(user)"
                    :key="i"
                    class="addons-settings__table-row"
                    data-cy="addons-settings-row"
                >
                    <div
                        class="addons-settings__row-cell"
                        data-cy="addons-settings-cell-email"
                    >
                        <kw-input-field
                            v-model.trim="user.email"
                            class="text-input"
                            :disabled="user.master"
                            :name="`addon-email-${i}`"
                            :placeholder="$t('example-domain')"
                            type="text"
                            :error-data="userInputError('email', i)"
                            @input="emailInputHandler(i)"
                        />
                        <span
                            v-if="user.master"
                            class="kw kw-lock"
                        />
                    </div>
                    <div
                        class="addons-settings__row-cell"
                        data-cy="addons-settings-cell-password"
                    >
                        <kw-input-field
                            v-model.trim="user.password"
                            class="text-input"
                            :disabled="user.master"
                            :name="`addon-password-${i}`"
                            :placeholder="passwordPlaceholder(user)"
                            type="password"
                            :error-data="userInputError('password', i)"
                            @input="passwordInputHandler(i)"
                            @blur="passwordBlurHandler(i)"
                        />
                        <span
                            v-if="user.master"
                            class="kw kw-lock"
                        />
                    </div>
                    <div
                        class="addons-settings__row-cell"
                        data-cy="addons-settings-cell-access"
                    >
                        <!-- Use the enable/disabled dropdown for -->
                        <custom-v-select
                            :id="user.master && 'masterAccess'"
                            :class="{'masterAccessDropdown': user.master}"
                            class="btn-block"
                            options-label="label"
                            options-value="value"
                            :options="getCustomSelectOptions(user)"
                            :value="getCustomSelectValue(user)"
                            @change="selectAccess(user, $event)"
                        />
                    </div>
                    <div
                        class="addons-settings__row-cell"
                        data-cy="addons-settings-cell-restrictions"
                    >
                        <div class="btn-group">
                            <kw-button
                                :append-icon-class="getIconClass(user)"
                                :button-disabled="isAdminUser(user) || user.master"
                                class="restrictions"
                                :button-text="getButtonText(user)"
                                button-type="secondary"
                                @click="openProjectsList(i)"
                            />
                        </div>
                        <span
                            v-if="user.master"
                            class="kw kw-information icon-info-in-cell"
                            @mouseover="tooltip({text:$t('please-add-one-admin'), event: $event})"
                        />
                        <span
                            v-else
                            class="kw kw-trash-2"
                            @click="deleteAddonUser(i)"
                        />
                    </div>
                </div>
            </template>
        </div>
        <addons-settings-mobile
            :addons="users"
            :delete-addon-user="deleteAddonUser"
            :email-input-handler="emailInputHandler"
            :headers="sharedAccessTableHeaders"
            :open-projects-list="openProjectsList"
            :password-blur-handler="passwordBlurHandler"
            :password-input-handler="passwordInputHandler"
            :password-placeholder="passwordPlaceholder"
            :select-access="selectAccess"
            :user-input-error="userInputError"
            :is-row-visible="isRowVisible"
            :get-icon-class="getIconClass"
            :get-button-text="getButtonText"
            :get-custom-select-options="getCustomSelectOptions"
            :get-custom-select-value="getCustomSelectValue"
        />
        <kw-button
            :button-text="$t('new-user')"
            button-type="text"
            prepend-icon-class="kw kw-plus"
            data-cy="add-addon-user-button"
            @click="addAddonUser"
        />
        <kw-button
            :button-disabled="saveButtonDisabled"
            :button-text="$t('save')"
            button-type="primary"
            data-cy="settings-save-button"
            @click="saveAddons"
        />
    </div>
</template>

<script>
    import {ADDON_ACCESS, MASTER_ACCESS} from '@/configs/accessConfig.json';
    import {logout} from '@/helpers/service';
    import {mapGetters, mapActions} from 'vuex';
    import {MOBILE_WIDTH_RESOLUTION_PHONE} from '@/constants';
    import {SET_USER_DATA} from '@/store/mutations';
    import {sharedAccessTableHeaders, sharedAccessOptions, sharedAccessEnableOptions} from './configs';
    import AddonsSettingsMobile from './components/AddonsSettingsMobile';
    import ProjectsListModal from 'components/modals/ProjectsListModal';
    import SimpleConfirmWithCustomHandler from 'components/modals/SimpleConfirmWithCustomHandler';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    import ValidationMixin from '@/mixins/ValidationMixin';
    import { ADMIN_ACCESS } from '@/configs/accessConfig.json';

    export default {
        name: 'SharedAccess',
        components: {
            'addons-settings-mobile': AddonsSettingsMobile,
        },
        mixins: [
            ToolTipRenderMixin,
            ValidationMixin,
        ],
        data () {
            return {
                masterDisabled: true,
                masterRowVisible: false,
                projects: [],
                sharedAccessEnableOptions,
                sharedAccessOptions,
                sharedAccessTableHeaders,
                users: [],
                err: {
                    email: [],
                    password: [],
                },
                //property "menu" need to remove class 'btn-group' from vue-sharp select, which is at the heart of our "custom-v-select",
                //You can see computed property "isLi" by which one of the classes is determined in node_modules/vue-strap/src/Select.vue
                menu: true,
            }
        },
        computed: {
            ...mapGetters([
                'getActiveProjects',
                'getAddonUsers',
                'getUserDataProp',
                'isMasterUser',
                'getSettingIsFetching',
            ]),
            saveButtonDisabled () {
                const master = this.users.filter(user => user.master === true);
                let masterAccessChanged = false;
                if (master && master.length > 0) {
                    masterAccessChanged = _.isEqual(master[0].disabled, this.masterDisabled) ? false : true;
                }
                return _.isEqual(this.users, this.prepareAddons()) && !masterAccessChanged;
            },
            adminUsersCount() {
                const admins = this.users.filter(user => user.access === 'admin');
                return admins.length;
            },
        },
        watch: {
            getActiveProjects: 'setProjects',
            getAddonUsers () {
                this.users = this.prepareAddons();
            },
            getSettingIsFetching (value) {
                // User settings were loaded, maybe show master
                if (value === false) {
                    this.users = this.prepareAddons();
                    this.setMasterAccess();
                }
            },
            'adminUsersCount': function(value) {
                if (value === 0) {
                    this.masterDisabled = false;
                    this.masterRowVisible = true;
                }
            },
            'masterDisabled': function(value) {
                if (true === value) {
                    this.$toastr.e(this.$t('disable-master-notice'));
                }
            },
        },
        async created () {
            this.unsubscribe = this.$store.subscribe(({type}) => {
                if (type === SET_USER_DATA) {
                    this.users = this.prepareAddons();
                    this.setMasterAccess();
                }
            });

            await this.recieveAddonUsers();
            this.users = this.prepareAddons();
            this.setMasterAccess();
            this.setProjects();
        },
        beforeDestroy () {
            if (typeof this.unSubscribe === 'function') {
                this.unSubscribe();
            }
        },
        methods: {
            ...mapActions([
                'addAddonUser',
                'recieveAddonUsers',
                'saveAddonUsers',
                'updateAddonUsers',
            ]),
            isAdminUser(user) {
                return user.access === ADMIN_ACCESS;
            },

            getIconClass(user) {
                return user.master ? 'kw kw-heart' : 'kw kw-chevron-down';
            },

            getButtonText(user) {
                return this.isAdminUser(user) ? this.$t('restrictions-placeholder-for-admin-access') : this.formattedProjects(user).text
            },

            getCustomSelectOptions(user) {
                return user.master ? sharedAccessEnableOptions : sharedAccessOptions
            },

            getCustomSelectValue(user) {
                return user.master ? this.masterDisabled : user.access
            },

            isRowVisible(user) {
                if (user.master) {
                    return this.masterRowVisible;
                }
                return true
            },
            prepareAddons () {
                if(this.getSettingIsFetching) {
                    return [];
                }

                const master = {
                    access: MASTER_ACCESS,
                    email: this.isMasterUser ? this.getUserDataProp('email') : this.getUserDataProp('addonMasterEmail'),
                    projects: this.$t('master-account'),
                    id: this.isMasterUser ? this.getUserDataProp('email') : this.getUserDataProp('addonMasterEmail'),
                    master: true,
                    password: 'password',
                    disabled: this.isMasterUser ? this.getUserDataProp('disabled') : this.getUserDataProp('addonMasterDisabled')
                };
                return [master, ..._.cloneDeep(this.getAddonUsers)];
            },
            setProjects () {
                this.projects.length = 0;
                this.projects.push({
                    auth: 'select-all',
                    children: false,
                    id: 'select-all',
                    name: this.$t('select-all'),
                    parent: '',
                    selectForAddProfile: false,
                    type: 'parent',
                });
                this.getActiveProjects.forEach(elem => {
                    this.projects.push({
                        auth: elem.auth,
                        children: elem.children,
                        id: elem.id,
                        name: elem.name,
                        parent: elem.parent,
                        selectForAddProfile: false,
                        type: 'parent',
                    });

                    if (elem.children) {
                        elem.children.forEach((el, i) => this.projects.push({
                            auth: el.auth,
                            id: el.id,
                            last: elem.children.length - 1 === i,
                            name: el.name,
                            parent: el.parent,
                            selectForAddProfile: false,
                            type: 'children',
                        }));
                    }
                });
            },
            setMasterAccess() {
                // get the master user and set the values
                if (this.users.find(user => user.master && user.disabled === false)) {
                    this.masterRowVisible = true;
                    this.masterDisabled = false;
                }
            },
            passwordPlaceholder (user) {
                return user.new && !user.newEmail ? this.$t('new-password') : this.$t('update-password');
            },
            formattedProjects (user) {
                const {projects, master} = user;
                return {
                    color: projects.length || master ? '' : '909399',
                    text: master
                        ? this.$t('master-account')
                        : projects.length > 1
                            ? `${projects.length} Selected`
                            : projects[0] || this.$t('select-restrictions'),
                };
            },
            selectAccess (user, value) {
                if (user.master) {
                    return this.masterDisabled = value;
                }
                if (value === ADMIN_ACCESS) {
                    user.projects.splice(0, user.projects.length)
                }
                user.access = value;
            },
            addAddonUser () {
                this.users.push({access: ADDON_ACCESS, email: '', projects: [], password: '', new: true});
            },
            deleteAddonUser (i) {
                this.users.splice(i, 1)
            },
            passwordInputHandler (i) {
                if (!this.users[i].password.length && !this.users[i].new) {
                    this.err.password[i] = {};
                    return;
                }

                this.err.password[i] = this.passwordValidator(this.users[i].password);
            },
            passwordBlurHandler (i) {
                if (this.users[i].new || this.users[i].newEmail) return;
                // - 1 because of master user
                if (this.getAddonUsers[i - 1].password !== this.users[i].password) {
                    this.$modal.show(
                        SimpleConfirmWithCustomHandler,
                        {
                            handler: async value => {
                                if (value) await this.saveAddons();
                            },
                            header: `<div>Save changes at password for</div><div class="email_block">${this.users[i].email}?</div>`,
                            positiveButton: this.$t('save'),
                        },
                        {
                            classes: 'v--modal center-modal-popup shared_access_modal',
                            clickToClose: window.screen.width > MOBILE_WIDTH_RESOLUTION_PHONE,
                            height: 'auto',
                        }
                    );
                }
            },
            emailInputHandler (i) {
                this.err.email[i] = this.singleEmailValidator(this.users[i].email);

                if (this.users[i].new) return;

                // if email modified
                this.getAddonUsers[i - 1].email !== this.users[i].email
                    ? this.users[i].newEmail = true
                    : delete this.users[i].newEmail;
            },
            openProjectsList (i) {
                const projects = _.cloneDeep(this.projects);

                if (this.users[i].projects.length) {
                    projects.forEach(el => {
                        const name = el.type === 'parent' ? el.name : `${el.parent}[sub]${el.name}`;
                        el.selectForAddProfile = this.users[i].projects.includes(name);
                    });

                    const allSelected = projects
                        .filter(el => el.auth !== 'select-all')
                        .every(el => el.selectForAddProfile);

                    if (allSelected) {
                        projects[0].selectForAddProfile = allSelected;
                    }
                }

                this.$modal.show(
                    ProjectsListModal,
                    {
                        projects,
                        selectHandler: projects => this.users[i].projects = projects,
                    },
                    {
                        classes: 'v--modal center-modal-popup',
                        height: 'auto',
                        width: window.screen.width > MOBILE_WIDTH_RESOLUTION_PHONE ? 530 : '90%',
                    },
                );
            },
            async saveAddons () {
                this.err.email = [];
                this.err.password = [];
                this.users.forEach((user, i) => {
                    this.err.email[i] = this.singleEmailValidator(user.email);

                    if (user.newEmail) {
                        user.new = user.newEmail;
                    }

                    if (!this.users[i].password.length && !user.new) {
                        this.err.password[i] = {};
                        return;
                    }

                    this.err.password[i] = this.passwordValidator(user.password);

                    if (user.newEmail && !user.password) {
                        this.err.password[i] = {
                            text: this.$t('enter-password-for', {email: user.email}),
                            type: 'error',
                        };
                    }
                });

                if (this.err.email.some(el => el.text) || this.err.password.some(el => el.text)) {
                    this.err.email.forEach(el => {
                        if (el && el.text) this.$toastr.e(el.text);
                    });

                    this.err.password.forEach(el => {
                        if (el && el.text) this.$toastr.e(el.text);
                    });

                    return;
                }

                // make sure there is at least an admin user if mastter access is disabled
                if (this.masterDisabled === true && this.adminUsersCount === 0) {
                    this.$toastr.e(this.$t('please-add-one-admin'));
                    return;
                }

                let payload = {};

                const newUsers = this.users.filter(user => !user.master && user.email);
                // we need to send empty user to remove last one addon
                payload.users = newUsers.length
                    ? newUsers
                    : [{access: ADDON_ACCESS, email: '', projects: [], password: ''}];
                payload.masterDisabled = this.masterDisabled;

                if (payload.masterDisabled) {
                    this.$modal.show(
                        SimpleConfirmWithCustomHandler,
                        {
                            handler: async value => {
                                if (value) {
                                    await this.saveAddonUsers(payload);
                                    logout(this);
                                }
                            },
                            header: this.$t('we-will-log-out'),
                            positiveButton: this.$t('ok'),
                        },
                        {
                            classes: 'v--modal center-modal-popup',
                            clickToClose: window.screen.width > MOBILE_WIDTH_RESOLUTION_PHONE,
                            height: 'auto',
                            width: 282,
                        }
                    );
                } else {
                    await this.saveAddonUsers(payload);
                }
            },
            userInputError (input, i) {
                return {
                    error: this.err[input][i] && this.err[input][i].text,
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
  @import '~sass/partials/dropdowns';

  .kw-information {
    color: #828F9C !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    margin: 0 4px;
  }

  .icon-info-in-cell {
    padding: 0 8px !important;
  }

  .addons-settings {
    margin: 0 auto 0 0;
    max-width: 1200px;

    /deep/ .kw-heart {
      color: kw-color(kw-grey, 1) !important;
      font-size: 12px;
      transform: rotate(0);
    }

    /deep/ .form-control.dropdown-toggle {
      @extend .custom-dropdown;
      color: kw-color(kw-black, 1);

      span {
        @extend .custom-scroll;
      }
    }

    /deep/ .open .form-control.dropdown-toggle {
      @extend .custom-dropdown_active;
    }

    /deep/ .dropdown-menu {
      @extend .custom-scroll;
      @extend .custom-dropdown-menu;
      width: 100%;

      .glyphicon.glyphicon-ok.check-mark:before {
        content: '';
      }
    }

    &__table {
      border: 1px solid #E1E8EF;
      display: flex;
      flex-flow: column nowrap;
      @media screen and (max-width: 960px) {
        display: none;
      }
    }

    &__table-header {
      background-color: rgba(38, 66, 98, .03);
      display: flex;
      flex-flow: row nowrap;
      height: 35px;
    }

    &__header-cell {
      align-items: center;
      border-right: 1px solid #E1E8EF;
      display: flex;
      flex: 1 0 160px;
      flex-flow: row nowrap;
      padding: 0 8px;

      &:last-child {
        border-right: none;
      }

      span {
        color: kw-color(kw-grey, 1);
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-transform: capitalize;
        user-select: none;
      }
    }

    &__table-row {
      background-color: kw-color(kw-white, 1);
      display: flex;
      flex-flow: row nowrap;
      height: 52px;

      &:nth-child(odd) {
        background-color: #F7F8FA;
      }

      input:disabled {
        padding-right: 24px;
      }
    }

    &__row-cell {
      align-items: center;
      border-right: 1px solid #E1E8EF;
      display: flex;
      flex: 1 0 160px;
      flex-flow: row nowrap;
      min-width: 0;
      padding: 8px;

      .text-input {
        width: 100%;
      }

      .kw-lock {
        color: kw-color(kw-grey, 1);
        font-weight: 700;
        margin-left: -25px;
      }

      .kw-trash-2 {
        color: kw-color(kw-grey, 1);
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        padding: 6px 4px;
      }

      /deep/ .kw-button {
        width: 100%;

        &.restrictions {
          justify-content: space-between;
          color: kw-color(kw-black, 1);
          border: 1px solid #AFBAC7 !important;
          font-weight: 400;
          box-shadow: none;
          padding: 8px 8px;

          span {
            font-size: 14px;
            line-height: 15px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.kw-chevron-down {
              font-size: 10px;
            }

            &.kw-heart {
              font-size: 14px;
              width: 15px;
            }
          }

          &:disabled {
            opacity: .5;
            color: kw-color(kw-black, 1);
            border: none;
            box-shadow: none;
          }
        }
      }

      /deep/ .btn-group {
          margin-right: 6px;
          width: calc(100% - 30px);
      }

      .masterAccessDropdown.disabled {
        /deep/ .dropdown-toggle {
          background-color: #E9EAEB;
        }
      }
    }

    /deep/ .kw-btn-primary {
      margin-top: 16px;
      width: 260px;
    }

    /deep/ .kw-btn-text {
      color: kw-color(kw-blue, 1) !important;
    }
  }
</style>
