export default (i18n, toastr) => {
    return res => {
        if (!res) return;

        const { config, data } = res;
        if (config.method === 'patch' && config.url.includes('/groups') && !config.url.includes('/keywords')) {
            toastr.s(i18n.t('project-updated'));
        }
        if (config.method === 'post' && config.url.includes('/duplicate')) {
            if (data.message) {
                toastr.i(data.message);
            } else {
                toastr.s(data.meta.title);
            }
        }
        return res;
    };
};