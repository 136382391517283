<template>
    <rotating-update-icon v-if="params.value === null" />
    <span v-else-if="!params.value" class="empty-value">&mdash;</span>
    <span v-else>
        {{ params.value.toLocaleString('en') }}
    </span>
</template>

<script>
import RotatingUpdateIcon from '@/components/ui-elements/RotatingUpdateIcon';
export default {
    name: 'VisibilityRenderer',
    components: { RotatingUpdateIcon },
};
</script>

<style lang="scss" scoped></style>
