import apiFactory from '@/api';
import i18n from '@/i18n';
import toastr from '@/helpers/init-toastr';
const legacyApi = apiFactory.get('legacy');
const whitelabelDomainsApi = apiFactory.get('whitelabelDomains');

import {
    SET_WHITELABEL_DOMAINS,
    SET_WHITELABEL_DOMAINS_LOADING,
    SET_WHITELABEL_DOMAIN_IS_ADDING,
    SET_WHITELABEL_DOMAIN_IS_DELETING,
    SET_WHITELABEL_DOMAIN_IS_VERIFYING,
} from '@/store/mutations';

const whitelabelDomains = {
    state: {
        whitelabelDomains: [],
        whitelabelDomainsLoading: false,
        whitelabelDomainIsAdding: false,
        whitelabelDomainIsDeleting: false,
        whitelabelDomainIsVerifying: false,
    },
    mutations: {
        [SET_WHITELABEL_DOMAINS]: (state, payload) => state.whitelabelDomains = payload,
        [SET_WHITELABEL_DOMAINS_LOADING]: (state, payload) => state.whitelabelDomainsLoading = payload,
        [SET_WHITELABEL_DOMAIN_IS_ADDING]: (state, payload) => state.whitelabelDomainIsAdding = payload,
        [SET_WHITELABEL_DOMAIN_IS_DELETING]: (state, payload) => state.whitelabelDomainIsDeleting = payload,
        [SET_WHITELABEL_DOMAIN_IS_VERIFYING]: (state, payload) => state.whitelabelDomainIsVerifying = payload,
    },
    getters: {
        getWhitelabelDomains: state => state.whitelabelDomains,
        getWhitelabelDomainsLoading: state => state.whitelabelDomainsLoading,
        getWhitelabelDomainIsAdding: state => state.whitelabelDomainIsAdding,
        getWhitelabelDomainIsDeleting: state => state.whitelabelDomainIsDeleting,
        getWhitelabelDomainIsVerifying: state => state.whitelabelDomainIsVerifying,
    },
    actions: {
        async fetchWhitelabelDomains({ commit, getters }) {
            if (!getters.getPermissionData('viewkey_reports')) {
                toastr.w(i18n.t('upgrade-for-white-label-reports'));
                return false;
            }

            commit(SET_WHITELABEL_DOMAINS, []);
            commit(SET_WHITELABEL_DOMAINS_LOADING, true);

            try {
                const res = await whitelabelDomainsApi.fetchWhitelabelDomains();
                commit(SET_WHITELABEL_DOMAINS, res.data.data || []);
            } catch (error) {
                return false;
            } finally {
                commit(SET_WHITELABEL_DOMAINS_LOADING, false);
            }
        },
        async addWhitelabelDomain({ dispatch, commit, getters }, domain) {
            if (!getters.getPermissionData('viewkey_reports')) {
                toastr.w(i18n.t('upgrade-for-white-label-reports'));
                return false;
            }

            commit(SET_WHITELABEL_DOMAIN_IS_ADDING, true);
            toastr.s(i18n.t('adding-domain'));

            try {
                const res = await legacyApi.addWhitelabelDomain(domain);

                if (res.status === 200 && res.data.response === 'success') {
                    toastr.s(i18n.t('updated-refreshing'));
                    dispatch('fetchWhitelabelDomains');
                } else {
                    toastr.e(res.data.message);
                }

                return res.data;
            } catch (error) {
                return false;
            } finally {
                commit(SET_WHITELABEL_DOMAIN_IS_ADDING, false);
            }
        },
        async verifyWhitelabelDomain({ dispatch, commit, getters }, domain) {
            if (!getters.getPermissionData('viewkey_reports')) {
                toastr.w(i18n.t('upgrade-for-white-label-reports'));
                return false;
            }

            commit(SET_WHITELABEL_DOMAIN_IS_VERIFYING, true);
            toastr.s(i18n.t('verifying-domain'));

            try {
                const res = await legacyApi.verifyWhitelabelDomain(domain);

                if (res.status === 200 && res.data.response === 'success') {
                    toastr.s(i18n.t('updated-refreshing'));
                    dispatch('fetchWhitelabelDomains');
                } else {
                    toastr.e(res.data.message);
                }
            } catch (error) {
                return false;
            } finally {
                commit(SET_WHITELABEL_DOMAIN_IS_VERIFYING, false);
            }
        },
        async deleteWhitelabelDomain({ dispatch, commit, getters }, domain) {
            if (!getters.getPermissionData('viewkey_reports')) {
                toastr.w(i18n.t('upgrade-for-white-label-reports'));
                return false;
            }

            commit(SET_WHITELABEL_DOMAIN_IS_DELETING, true);
            toastr.s(i18n.t('deleting-domain'));

            try {
                const res = await legacyApi.deleteWhitelabelDomain(domain);

                if (res.status === 200 && res.data.response === 'success') {
                    toastr.s(i18n.t('message-refreshing', { message: res.data.message }));
                    dispatch('fetchWhitelabelDomains');
                } else {
                    toastr.e(res.data.message);
                }
            } catch (error) {
                return false;
            } finally {
                commit(SET_WHITELABEL_DOMAIN_IS_DELETING, false);
            }
        },
    }
};

export default whitelabelDomains;
