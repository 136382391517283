import { mapActions } from 'vuex';
import { cookieGetters } from '@/helpers/cookieManager';
import SocialSignIn from '@/components/ui-elements/SocialSignIn';
import { INTEGRATED_SOCIAL_MEDIA_NAMES } from '@/helpers/social-auth-service';
import { getGoogleAuthLink, getSocialAuthLink } from "@/helpers/social-auth-service";

export default {
    components: {
        'social-sign-in': SocialSignIn
    },
    data() {
        return {
            INTEGRATED_SOCIAL_MEDIA_NAMES,

            error: this.$route.query.error_message,
            provider: this.$route.query.provider,
            code: this.$route.query.code,
            redirect_uri: this.$route.query.redirect_uri,
            socialLoading: false,
            sub: true
        };
    },
    async created() {
        this.googleAuthLink = getGoogleAuthLink(this.service);

        if (this.code && this.provider && this.redirect_uri) {
            try {
                const trial_period = this.$route.query?.trial == 1;
                this.socialLoading = true;
                await this.$recaptchaLoaded();
                this.recaptcha = await this.$recaptcha('social_sign_in');
                const newsletterSubscription = window.sessionStorage.getItem('newsletterSubscription') !== '0'
                window.sessionStorage.removeItem('newsletterSubscription')

                const data = {
                    code: this.code,
                    redirect_uri: this.redirect_uri,
                    plan_id: this.currentPackage?.package_identity || process.env.FREE_PLAN_ID,
                    expire_post_trial: this.expirePostTrial,
                    billing_cycle: this.getBillingCycle,
                    trial_period: this.$route.query['trial-period'],
                    recaptcha: this.recaptcha,
                    ga_client_id: cookieGetters.getGaClientId(),
                    mixpanel_id: cookieGetters.getMixpanelId(),
                    first_visit_date: cookieGetters.getFirstVisitDate(),
                    ...cookieGetters.getUtmAndMarketingParameters(),
                    referral: window.Rewardful?.referral ?? null,
                    newsletter_subscription: newsletterSubscription
                };

                this.error = await this.socialSignIn({ provider: this.provider, data, trial_period });
            } catch (error) {
                if (this.mixinResource ==='sign-up') {
                    this.trackSignupFailedEvent(this.provider ?? 'other', error);
                }
            } finally {
                this.socialLoading = false;
            }
        }

        if (this.error) {
            let msg = this.error?.response?.data?.errors[0]?.title
                ?? this.error?.[0]?.detail
                ?? this.error;
            this.$toastr.e(msg);

            if (this.mixinResource ==='sign-up') {
                this.trackSignupFailedEvent(this.provider ?? 'other', msg);
            }
        }
    },
    methods: {
        ...mapActions(['socialSignIn']),
        openSocialSignIn(social) {
            this.sub = false
            const socialAuthLink = getSocialAuthLink(social);

            if (this.mixinResource ==='sign-up') {
                this.trackSignupStartEvent(social, this.username);
            }

            if (socialAuthLink) {
                this.locateTo(socialAuthLink);
            }
        },
        locateTo(link) {
            let h = 700;
            let w = 500;
            let t = screen.height / 2 - h / 2;
            let l = screen.width / 2 - w / 2;

            window.open(
                link,
                "_blank",
                `toolbar=0,location=0,menubar=0,left=${l},top=${t},height=${h},width=${w},alwaysRaised=yes`
            );
        },
    },
};
