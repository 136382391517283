import { REPORT_GENERATION_STATUS } from '@/constants';

export default (i18n, toastr) => {
    return res => {
        if (!res) return;

        const { config, data } = res;

        switch (config.method) {
            case 'post':
                if (config.url.includes('/download')) {
                    toastr.s(i18n.t('generating-report'));
                }
                break;

            case 'patch':
                if (config.url.includes('/set-recipients')) {
                    //
                }
                break;

            case 'get':
                if (config.url.includes('/check-status')) {
                    switch (data.data.status) {
                        case REPORT_GENERATION_STATUS.FAILED:
                            toastr.e(i18n.t('download-failed'));
                            break;
                        case REPORT_GENERATION_STATUS.FILE_SENT:
                            toastr.s(i18n.t('report-sent-to-email'));
                            break;
                    }
                }
                break;
        }

        return res;
    };
};
