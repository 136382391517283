export default (i18n, toastr) => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config, response } = error;

        if (!config || !response) {
            return Promise.reject(error);
        }

        const { errors } = response.data;

        if (config.url.includes('/tags')) {
            errors?.forEach(element => {
                toastr.e(element.detail);
            });
        }

        return Promise.reject(error);
    };
};
