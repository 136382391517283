<template>
    <vue-draggable-resizable
        v-if="isDraggableShown"
        :key="sidebarKey"
        :class-name="vdrLeftSidebarClasses"
        class-name-handle="handle handle-mr handle_left-sidebar"
        :draggable="false"
        :handles="['mr']"
        :h="height"
        :max-width="750"
        :resizable="isSidebarResizable"
        :w="sidebarWidth"
        :z="1000"
        @resizing="(x, y, w) => sidebarWidthResizing(w)"
        @resizestop="(x, y, w) => updateSidebarWidth(w)"
    >
        <div
            class="left-sidebar-hidden-shell"
            :class="{ with_top_banner: isTrialPeriodHeaderVisible }"
            :style="{
                left: `${isLeftSidebarOpen ? -sidebarWidth : 0}px`,
                width: `${sidebarWidth}px`,
            }"
        />
        <div class="left-sidebar" data-cy="left-sidebar">
            <div class="sidebar-header">
                <search
                    :default-search="getUserSetting('projectsSearch')"
                    @search-changed="
                        debouncedInputHandler(() => searchChanged($event))
                    "
                />
            </div>
            <div :class="sidebarContentClasses">
                <active-projects :categories="getFilteredAndSortedProjects" />
            </div>
            <div class="sidebar-footer" v-if="getTutorialMode === false">
                <footer-section :open-add-project="openAddProject" />
            </div>
        </div>

        <global-events target="window" @resize="resizeHandler" />
        <div v-if="isOverlayVisible" class="side_bar_loading_overlay" />
    </vue-draggable-resizable>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Search from 'components/left-sidebar/components/Search';
import FooterSection from 'components/left-sidebar/components/FooterSection';
import ActiveProjects from 'components/left-sidebar/components/ActiveProjects';

import { Events, EventBus } from '@/events';
import { SET_SIDEBAR_WIDTH } from '@/store/mutations';
import { cookieManager } from '@/helpers/cookieManager';
import {
    MOBILE_WIDTH_RESOLUTION_PHONE,
    MINIMAL_LEFT_SIDEBAR_WIDTH,
    MIXPANEL_EVENT_CONSTANTS,
    TYPING_DEBOUNCE,
} from '@/constants';

export default {
    name: 'LeftSidebar',
    components: {
        search: Search,
        'footer-section': FooterSection,
        'active-projects': ActiveProjects,
    },
    data() {
        return {
            sidebarKey: 0,
            height: window.innerHeight - (this.getTrialPeriod ? 48 : 0) - 68,
            debouncedInputHandler: _.debounce(fn => fn(), TYPING_DEBOUNCE),
        };
    },
    computed: {
        ...mapGetters([
            'getActiveTag',
            'isMasterUser',
            'getCurrentPlan',
            'getTrialPeriod',
            'getUserSetting',
            'getSidebarWidth',
            'getTutorialMode',
            'isLeftSidebarOpen',
            'getKeywordsIsFetching',
            'getAverageRanksDataLoading',
            'getFilteredAndSortedProjects',
            'getMindshareHistoryDataLoading',
        ]),
        isSidebarResizable() {
            return (
                this.isLeftSidebarOpen &&
                MOBILE_WIDTH_RESOLUTION_PHONE < window.screen.width
            );
        },
        sidebarWidth() {
            return MOBILE_WIDTH_RESOLUTION_PHONE < window.screen.width
                ? this.getSidebarWidth
                : MINIMAL_LEFT_SIDEBAR_WIDTH;
        },
        sidebarContentClasses() {
            return {
                'sidebar-content': true,
                with_top_banner: this.isTrialPeriodHeaderVisible,
                'sidebar-content_addon-user': !this.isMasterUser,
            };
        },
        vdrLeftSidebarClasses() {
            return `vdr vdr_left-sidebar${
                this.isLeftSidebarOpen ? '' : ' vdr_left-sidebar-closed'
            }${this.isTrialPeriodHeaderVisible ? ' with_top_banner' : ''}`;
        },
        isOverlayVisible() {
            return (
                (this.getMindshareHistoryDataLoading ||
                    this.getAverageRanksDataLoading ||
                    this.getKeywordsIsFetching) &&
                (this.$route.name === 'keywordList' ||
                    this.$route.name === 'searchResults')
            );
        },
        isDraggableShown() {
            return cookieManager.isKey('api_key');
        },
        isTrialPeriodHeaderVisible(){
            return (
                !this.getCurrentPlan?.default_card?.type &&
                this.getTrialPeriod
            );
        }
    },
    watch: {
        async $route({ path }, prev) {
            if (path === '/' && prev.path !== '/archived') {
                await this.fetchActiveProjects();
            }
        },
        getTutorialMode(to, from) {
            if (to === false && from === null) {
                this.fetchActiveProjects();
            }
        },
    },
    created() {
        this.unSubscribe = this.$store.subscribe(({ type }) => {
            if (type === SET_SIDEBAR_WIDTH) {
                // to update sidebar if width less than minimal
                this.sidebarKey += 1;
            }
        });
        if (this.getTrialPeriod) {
            this.resizeHandler();
        }
    },
    async mounted() {
        if (this.getTutorialMode === false) {
            await this.fetchActiveProjects();
        }
    },
    beforeDestroy() {
        if (typeof this.unSubscribe === 'function') {
            this.unSubscribe();
        }
    },
    methods: {
        ...mapActions([
            'fetchActiveProjects',
            'sidebarWidthResizing',
            'updateSidebarWidth',
            'setSideBarOpened',
            'setProjectsSearch',
            'toggleAddProjectScreenOpened',
        ]),
        hideSideBar() {
            this.setSideBarOpened(false);
        },
        async searchChanged(searchText) {
            this.setProjectsSearch(searchText);
        },
        openAddProject() {
            if (this.getCurrentPlan.expiredStatus === 'expired') {
                this.$toastr.w(this.$t('please-renew-subscription'));
                return;
            }

            EventBus.emit(Events.PROJECT_ADD_INITIATED_EVENT, {
                project_source:
                    MIXPANEL_EVENT_CONSTANTS.PROJECT_INITIATING_SOURCE
                        .SIDE_PANEL,
            });
            this.toggleAddProjectScreenOpened();
        },
        resizeHandler() {
            this.height = window.innerHeight - (this.getTrialPeriod ? 48 : 0);
        },
    },
};
</script>

<style lang="scss">
.left-sidebar-hidden-shell {
    position: absolute;
    height: calc(100vh - #{$header-height});
    z-index: 100000;

    &.with_top_banner {
        height: calc(100vh - #{$header-height} - #{$trial-header-height});
    }
}
.main-content{
    &> .vdr {
        top: $header-height;

        &.with_top_banner{
            top: calc(#{$header-height} + #{$trial-header-height});
        }

        &.vdr_left-sidebar {
            background-color: #f5f6f7;
            border: 0;
            border-right: 1px solid #e1e8ef;
            display: flex;
            padding: 0 0 8px 8px;
            position: fixed;
            transition: transform .3s ease, opacity .3s ease;
            will-change: width;
        }

        &.vdr_left-sidebar-closed {
            opacity: 0;
            transform: translateX(-100%) !important;
        }
    }
}

.handle {
    &.handle_left-sidebar {
        background-color: transparent;
        border: 0;
        cursor: ew-resize;
        display: block !important;
        height: 100vh;
        margin-top: 0;
        top: 0;
        width: 10px;
        z-index: 1001;
    }
}

.left-sidebar {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    .show-vertical-menu {
        background: kw-color(kw-white, 1);
        border-radius: 50%;
        color: kw-color(kw-blue, 1);
        cursor: pointer;
        font-size: 10px;
        height: 20px;
        padding: 0;
        position: absolute;
        right: -9px;
        top: 21px;
        width: 20px;
        z-index: 1002;

        &:before {
            font-weight: bold;
            line-height: 22px;
            padding-left: 5px;
        }
    }
}

.side_bar_loading_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba($color: #000000, $alpha: 0.5);
    opacity: 0.5;
    cursor: progress;
}
</style>
