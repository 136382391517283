export default (i18n, toastr) => {
    return res => {
        if (!res) return;

        const {config} = res;
        
        if (config.method === 'patch' && config.url.includes('/user/invoices/options')) {
            toastr.s(i18n.t('invoice-options-save-success-msg'));
        }

        return res;
    };
};