import apiFactory from '@/api';
import { Events, EventBus } from '@/events';
import {SET_ALERT_SETTINGS} from '@/store/mutations';
const alertsApi = apiFactory.get('alerts');

const alertsSettings = {
    state: {
        alertsSettings: {
            alert_emails: '',
            alert_meat: null,
        }
    },
    mutations: {
        [SET_ALERT_SETTINGS]: (state, payload) => state.alertsSettings = payload,
    },
    getters: {
        getAlertsSetting: state => prop => state.alertsSettings[prop],
        getAlertsSettings: state => state.alertsSettings,
    },
    actions: {
        async updateAlertsSettings ({dispatch}, settings) {
            const emails = settings.alert_emails === ''
                ? null
                : settings.alert_emails.split(',').map(email => email.trim());

            const queryData = {
                data: {
                    emails: emails,
                    frequency: settings.alert_meat
                },
            };

            try {
                await alertsApi.update(queryData);
                EventBus.emit(Events.UPDATE_ALERT_SETTINGS, queryData.data);
                dispatch('setAlertSettings', settings);
            } catch (error) {
                return false;
            }
        },
        setAlertSettings ({commit}, payload) {
            const {
                alert_emails,
                alert_meat,
            } = payload;
            const dataToUpdate = {
                alert_meat: alert_meat || null,
                alert_emails: Array.isArray(alert_emails) ? alert_emails.join(',') : alert_emails,
            }
            commit(SET_ALERT_SETTINGS, dataToUpdate)
        }
    },
};

export default alertsSettings;
