<template>
    <div class="screen__content screen__content_keyword">
        <div class="screen__column">
            <div class="screen__field screen__field_keyword keyword-url-wrap">
                <label class="text-label" for="keyword-domain-map">
                    {{ $t('enter-url') }}
                </label>
                <div class="url-input-wrap" data-cy="url-input-maps-wrap">
                    <div class="protocol-type">
                        <span>{{ $t('https') }}</span>
                    </div>
                    <vue-simple-suggest
                        ref="suggestComponent"
                        v-model.trim="newKeyword.url"
                        :filter-by-query="true"
                        :list="getSuggestedUrls(apiKeywordTypes)"
                        :min-length="0"
                        :styles="autocompleteStyles"
                        @input="() => urlInputHandler()"
                    >
                        <input
                            id="keyword-domain-map"
                            v-model.trim="newKeyword.url"
                            autocomplete="off"
                            :class="keywordDomainClasses"
                            data-cy="add-keyword-maps-url-input"
                            :placeholder="$t('domain-url')"
                            type="text"
                        />
                        <div slot="suggestion-item" slot-scope="scope">
                            <span v-html="boldenSuggestion(scope, $refs)" />
                        </div>
                    </vue-simple-suggest>
                </div>
            </div>
            <div
                class="screen__field screen__field_keyword screen__field_toggler keyword-type-wrap"
            >
                <span class="keyword-type-title">{{ $t('keywords') }}</span>
            </div>
            <div
                class="screen__field screen__field_keyword keywords-seggestions-wrap"
            >
                <form autocomplete="off" class="keywords-wrap" @submit.prevent>
                    <div class="title-wrap keywords-title">
                        <label class="text-label keywords-label" for="keywords">
                            <span>
                                {{ $t('keyword-to-track') }}
                            </span>
                            <span
                                class="kw kw-information"
                                @mouseover="
                                    tooltip({
                                        text: $t('keywords-tooltip'),
                                        event: $event,
                                        maxWidth: 166,
                                    })
                                "
                            />
                        </label>
                    </div>
                    <div class="keywords-teaxtarey-wrap">
                        <textarea
                            id="google-keywords"
                            v-model.trim="textarea"
                            :class="keywordsClasses"
                            data-cy="add-keyword-map-keyword-textarea"
                            :placeholder="$t('input-one-keyword-per-line')"
                            @input="keywordsInputHandler"
                        />
                    </div>
                    <suggestions-component
                        :show="isMobile"
                        :new-keyword="newKeyword"
                        :show-suggested-keywords="showSuggestedKeywords"
                        :add-all-suggested-into-keywords="
                            addAllSuggestedIntoKeywords
                        "
                        :toggle-keyword-source="MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD"
                        :toggle-keyword="toggleKeyword"
                    />
                    <div class="region-language-wrap">
                        <div
                            class="screen__field screen__field_keyword screen__field_select-region"
                        >
                            <label class="text-label" for="search-region-map">
                                <span>{{ $t('google-region') }}</span>
                                <span
                                    class="kw kw-information"
                                    @mouseover="
                                        tooltip({
                                            text: $t('region-tooltip'),
                                            event: $event,
                                        })
                                    "
                                />
                            </label>
                            <custom-v-select
                                id="search-region-map"
                                :class="{
                                    'custom-dropdown_filled':
                                        newKeyword.region.value,
                                }"
                                data-cy="add-keyword-web-region"
                                dropdown-search-data-cy="region-search-field-map"
                                dropdown-menu-data-cy="region-dropdown-menu-map"
                                :options="
                                    googleRegions(
                                        getSuggestedRegions,
                                        regionsForSelect,
                                    )
                                "
                                options-label="name"
                                select-type="region-map"
                                options-value="value"
                                :placeholder="$t('region-tooltip')"
                                :value.sync="newKeyword.region.value"
                                search
                                @change="selectRegionValue"
                                @selected="selectRegionLabel"
                            />
                        </div>
                    </div>
                    <add-locations
                        autocomplete-type="web-domain-location-map"
                        ref-name="newMapKeywordLocation"
                        :add-location="addLocation"
                        :enable-validation="true"
                        :invalid="!!err.location.text"
                        :keyword-type="keywordType"
                        :locations="newKeyword.geolocation.location"
                        :remove-location="removeLocation"
                        :set-location-errors="locationInputsHandler"
                        :set-location="setLocation"
                    />
                    <gmb-autocomplete-input
                        ref="mapGMBInputRef"
                        :geotype="geotype"
                        :input-change-handler="handleGmbChange"
                        :invalid="!!err.gmb.text"
                        :keyword-draft="getKeywordDraft(keywordType)"
                        :keyword-type="keywordType"
                        :on-blur-gmb="onBlurGMB"
                        :place-changed-handler="getGMBDataForNewKeyword"
                        wrap-class="gmb-wrap pt-12"
                    />
                    <div class="button-wrap">
                        <kw-button
                            :button-text="
                                $t('plus-add-keywords-count', {
                                    count: keywordsCount,
                                })
                            "
                            button-type="primary"
                            class="screen__add-button gmt-add-keyword-btn"
                            prepend-icon-class="kw kw-plus"
                            data-cy="add-keyword-map-save"
                            :loading="getKeywordAdding"
                            :disabled="isAddButtonActive"
                            @click="handleAddKeywordsClick"
                        />
                    </div>
                </form>
                <suggestions-component
                    :show="!isMobile"
                    :new-keyword="newKeyword"
                    :show-suggested-keywords="showSuggestedKeywords"
                    :add-all-suggested-into-keywords="
                        addAllSuggestedIntoKeywords
                    "
                    :toggle-keyword-source="MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD"
                    :toggle-keyword="toggleKeyword"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { autocompleteStyles, boldenSuggestion } from '../utils';
import {
    TYPING_DEBOUNCE,
    DEFAULT_REGION,
    DEFAULT_LANGUAGE,
    MIXPANEL_EVENT_CONSTANTS,
} from '@/constants';
import { Events, EventBus } from '@/events';
import { isMobileWidth } from '@/helpers/is-mobile-width';
import { mapGetters, mapActions } from 'vuex';
import { regionsForSelect } from '@/helpers/regions-service';
import { SET_SUGGESTED_REGIONS, SET_SUGGESTED_URLS } from '@/store/mutations';
import AddKeywordsMixin from '@/mixins/AddKeywordsMixin';
import AddLocations from '@/components/add-keywords/AddLocations';
import GoogleApiMixin from '@/mixins/GoogleApiMixin';
import SuggestionsComponent from './SuggestionsComponent';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import VueSimpleSuggest from 'vue-simple-suggest';
const GMBAutocompleteInput = () => import('./GMBAutocompleteInput');
export default {
    name: 'GoogleLocalMapsTab',
    components: {
        'add-locations': AddLocations,
        'gmb-autocomplete-input': GMBAutocompleteInput,
        'suggestions-component': SuggestionsComponent,
        'vue-simple-suggest': VueSimpleSuggest,
    },
    mixins: [
        GoogleApiMixin,
        ValidationMixin,
        AddKeywordsMixin,
        ToolTipRenderMixin,
    ],
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            debouncedHandler: _.debounce(fn => fn(), TYPING_DEBOUNCE),
            apiKeywordTypes: ['map'],
            autocompleteStyles,
            boldenSuggestion,
            draftSettedNoSuggestions: false,
            isKeywordAdded: false,
            keywordType: 'map',
            regionsForSelect,
            textarea: '',
            newKeyword: {
                url: '',
                region: {
                    name: null,
                    value: DEFAULT_REGION,
                },
                language: {
                    name: null,
                    value: DEFAULT_LANGUAGE,
                },
                keywords: [],
                geolocation: {
                    changeLocation: false,
                    location: [''],
                    GMB: '',
                    isManualGmb: false,
                },
            },
            err: {
                gmb: {},
                keywords: {},
                location: {},
                url: {},
            },
            MIXPANEL_EVENT_CONSTANTS,
        };
    },
    computed: {
        ...mapGetters([
            'getSuggestions',
            'getKeywordAdding',
            'getKeywordDraft',
            'getSuggestedRegions',
            'getSuggestedUrls',
            'getCurrentProject',
            'getSuggestionsPaginated',
        ]),
        keywordsCount() {
            const kwLength = this.newKeyword.keywords.length;
            const kwsLength = this.newKeyword.keywords.join('\n').length;
            const locationLength = this.newKeyword.geolocation.location.length;
            return kwLength && kwsLength ? locationLength * kwLength : 0;
        },
        isAddButtonActive() {
            let isError = false;

            Object.keys(this.err).map(item => {
                if (this.err[item].text) {
                    isError = true;
                }
            });

            let keywordsLength = 0;
            this.newKeyword.keywords.map(
                item => (keywordsLength += item.length),
            );
            return (
                isError ||
                !this.newKeyword.url.length ||
                !keywordsLength ||
                !this.newKeyword.geolocation.GMB ||
                this.newKeyword.geolocation.location.every(l => !l)
            );
        },
        showSuggestedKeywords() {
            return (
                !!this.newKeyword.keywords.join('\n').length &&
                !!this.getSuggestionsPaginated.length
            );
        },
        isMobile() {
            return isMobileWidth();
        },
        keywordDomainClasses() {
            return {
                'domain-filed': true,
                'text-input': true,
                'text-input_invalid': this.err.url.text,
            };
        },
        keywordsClasses() {
            return {
                screen__textarea_keywords: true,
                'text-input_invalid': this.err.keywords.text,
                'text-input': true,
            };
        },
    },
    watch: {
        getCurrentProject: 'emitViewEvent',
        active: 'emitViewEvent',
    },
    created() {
        this.unSubscribe = this.$store.subscribe(({ type }) => {
            if (type === SET_SUGGESTED_REGIONS) {
                this.setDraftOrDefaultValues();
            }
            if (type === SET_SUGGESTED_URLS) {
                this.setSingleDomain();
            }
        });
    },
    mounted() {
        this.urlInputHandler();
        this.setDraftOrDefaultValues();
        this.setSingleDomain();

        this.emitViewEvent();
    },
    beforeDestroy() {
        if (typeof this.unSubscribe === 'function') {
            this.unSubscribe();
        }
    },
    methods: {
        ...mapActions([
            'fetchSuggestions',
            'addKeyword',
            'setKeywordDraft',
            'setDraftsQuantity',
        ]),
        emitViewEvent() {
            if (!this.getCurrentProject || !this.active) {
                return;
            }

            EventBus.emit(Events.ADD_KEYWORD_MAPS_VIEW_EVENT, {
                project_name: this.getCurrentProject.name,
                leading_source:
                    MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD,
            });
        },
        setSingleDomain() {
            if (this.getSuggestedUrls(this.apiKeywordTypes).length === 1) {
                this.$refs.suggestComponent.select(
                    this.getSuggestedUrls(this.apiKeywordTypes)[0],
                );
            }
        },
        setDraftOrDefaultValues() {
            if (
                this.getKeywordDraft(this.keywordType) &&
                !_.isEqual(
                    this.getKeywordDraft(this.keywordType),
                    this.newKeyword,
                )
            ) {
                this.newKeyword = this.getKeywordDraft('map');
                this.textarea = this.newKeyword.keywords.join('\n');
                this.fetchSuggestions(this.newKeyword.keywords);
                this.draftSettedNoSuggestions = true;
            }

            if (!this.draftSettedNoSuggestions) {
                const firstRegion = this.getSuggestedRegions(
                    this.apiKeywordTypes,
                )[0];

                if (firstRegion) {
                    this.newKeyword.region.value = firstRegion.region;
                } else {
                    this.newKeyword.region.value = DEFAULT_REGION;
                }
            }
        },
        selectRegionValue(value) {
            this.newKeyword.region.value = value;
        },
        selectRegionLabel(label) {
            this.newKeyword.region.name = label[0];
        },
        validateTextarea() {
            this.newKeyword.keywords = this.textarea.split('\n');
            this.err.keywords = this.newKeyword.keywords.every(el => !el)
                ? { text: this.$t('enter-least-one-keyword'), type: 'error' }
                : {};
        },
        urlInputHandler(sendRequest) {
            this.err.url = this.urlValidator(this.newKeyword.url);

            if (!sendRequest) {
                const str = this.getRegionDomain(this.newKeyword.url);

                if (str) {
                    const regions = regionsForSelect.filter(
                        el => str === el.value.replace('google.', ''),
                    );
                    const region = !regions.length
                        ? regionsForSelect.find(el => el.value === 'google.com')
                        : regions.length > 1
                        ? regions[1]
                        : regions[0];

                    this.selectRegionValue(region.value);
                    this.selectRegionLabel(region.name);
                }
            }
        },
        async keywordsInputHandler() {
            this.validateTextarea();
            this.debouncedHandler(
                async () =>
                    await this.fetchSuggestions(this.newKeyword.keywords),
            );

            this.trackKeywordsListed();
        },
        trackKeywordsListed() {
            EventBus.emit(Events.KEYWORDS_LISTED_EVENT, {
                num_keywords: this.newKeyword.keywords.length,
                num_keywords_suggested: this.getSuggestions.length,
                bulk_import_enabled: false,
                keyword_type:
                    MIXPANEL_EVENT_CONSTANTS.KEYWORD_TYPE[this.keywordType],
                leading_source:
                    MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE
                        .PROJECT_DASHBOARD,
            });
        },
        locationInputsHandler() {
            this.err.location = this.newKeyword.geolocation.location.every(
                el => !el,
            )
                ? { text: this.$t('location-should-be-valid'), type: 'error' }
                : {};
        },
        handleAddKeywordsClick() {
            if (
                (!this.newKeyword.geolocation.GMB &&
                    this.$refs.mapGMBInputRef.$refs.newMapKeywordGMB
                        .autocompleteText) ||
                (this.newKeyword.geolocation.isManualGmb &&
                    this.getKeywordDraft(this.keywordType))
            ) {
                this.showManualGMBModal(
                    this.$refs.mapGMBInputRef.$refs.newMapKeywordGMB,
                    this.addKeywords,
                );
                return;
            }
            this.addKeywords();
        },
        async addKeywords() {
            this.urlInputHandler(true);
            this.validateTextarea();
            this.removeEmptyLocations();
            this.locationInputsHandler();
            this.err.gmb = !this.newKeyword.geolocation.GMB
                ? { text: this.$t('gmb-should-be-valid'), type: 'error' }
                : {};

            if (Object.keys(this.err).some(key => this.err[key].text)) {
                Object.keys(this.err).forEach(key => {
                    if (this.err[key].text) {
                        this.$toastr.e(this.err[key].text);
                    }
                });

                return;
            }

            await this.sendRequest();
        },
        async sendRequest() {
            await this.addKeyword({
                keyword: { ...this.newKeyword },
                type: this.keywordType,
                exact: true,
                leading_source: MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD,
            });

            this.isKeywordAdded = true;
            this.setKeywordDraft({ type: this.keywordType, payload: null });
            this.setDraftsQuantity({ payload: 0, type: this.keywordType });

            this.$router.push({
                name: 'keywordList',
                params: this.$route.params,
            });
        },
        handleGmbChange({ oldVal }) {
            if (oldVal) {
                this.resetGMB();
                this.newKeyword.geolocation.isManualGmb = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/dropdowns';
@import '~sass/partials/tutorial';
@import '~sass/partials/custom-simple-suggest';

.keywords-teaxtarey-wrap {
    min-height: 547px !important;
}

.screen__content_keyword {
    padding-top: 17px;
}

.screen__question {
    @extend .tutorial-question;
    align-self: center;
    margin-left: 2px;
}

.url-input-wrap {
    .text-input {
        width: 100%;
        max-width: unset !important;
    }
}

.region-language-wrap {
    .screen__field,
    .custom-dropdown_filled,
    .form-control {
        width: 100% !important;
        max-width: unset !important;
    }
}

.screen__field_keyword {
    label {
        user-select: none;
    }

    /deep/ .open .form-control.dropdown-toggle {
        @extend .custom-dropdown_active;
    }

    .custom-dropdown_filled {
        /deep/ .form-control.dropdown-toggle {
            color: kw-color(kw-black, 1);
        }
    }

    /deep/ .dropdown-menu {
        width: 100%;
        @media screen and (max-width: 959px) {
            width: 100%;
            min-width: 320px;
        }
        @extend .custom-scroll;
        @extend .custom-dropdown-menu;

        .glyphicon.glyphicon-ok.check-mark:before {
            content: '';
        }
    }
}

.screen__field_select-region {
    padding-top: 14px;
    padding-bottom: 24px;
}

.gmb-wrap {
    border-top: 1px solid #dee5ec;
    padding: 12px 14px;
}

.screen__add-button {
    width: 320px;
    @media screen and (max-width: 959px) {
        width: 100%;
    }
    margin-top: 16px;
}
</style>
