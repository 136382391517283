<template>
    <div class="export-progress-bar-modal" data-cy="export-progress-bar-modal">
        <div class="header">{{ $t("export-project") }}</div>

        <div class="body">
            <div class="export-keywords-msg">
                {{ $t("exporting", { count: keywordsCount }) }}
            </div>

            <progress-bar
                dataCy="export-progress-bar"
                :all="readyStatus"
                :fill="status"
            />

            <div v-if="sendTo" class="export-email-send-to-msg">
                <span v-html="$t('export-email-send-to-msg', { email })"></span>
            </div>
            <form method="GET" @submit.prevent="validateEmail" v-else-if="!hideEmailForm">
                <div class="form-group">
                    <kw-button
                        id="set-email-button"
                        prepend-icon-class="kw kw-icon-mail"
                        button-type="primary"
                        type="submit"
                        :loading="sendToLoading"
                    />
                    <kw-input-field
                        class="text-input"
                        id="email"
                        type="email"
                        name="Email"
                        v-model.trim="email"
                        v-validate="'required|email'"
                        :disabled="sendToLoading"
                        :placeholder="$t('name-email')"
                        :error-data="{
                            error: this.errors.collect('Email').length,
                        }"
                    />
                </div>
            </form>
        </div>

        <div class="footer">
            <kw-button
                class="close-export-progress-bar-modal"
                data-cy="close-export-progress-bar-modal"
                button-type="primary"
                :button-text="buttonText"
                @click="close"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { REPORT_GENERATION_STATUS } from "@/constants";
import ProgressBar from "components/left-sidebar/components/ProgressBar";
import { detectIsSurferSEO } from '@/helpers';

export default {
    name: "ExportModal",
    components: {
        "progress-bar": ProgressBar,
    },
    props: {
        project: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            "getViewkeyView",
            "getUserDataProp",
            "getActivityLog",
            "getTotalKeywords",
            "getActivityLogs",
            "getActivityLogsFetching",
        ]),
        keywordsCount() {
            return this.getTotalKeywords
                ? this.getTotalKeywords
                : this.project?.keywords_count?.ACTIVE ?? 0;
        },
        reportIsGenerating() {
            return (
                this.currentActivity &&
                this.currentActivity.report_activity_log_id
            );
        },
        buttonText() {
            return this.reportIsGenerating
                ? this.$t("close")
                : this.$t("cancel");
        },
        hideEmailForm(){
            return this.getViewkeyView && detectIsSurferSEO(); 
        }
    },
    data() {
        return {
            email: "",
            sendTo: false,
            sendToLoading: false,
            currentActivity: null,
            status: REPORT_GENERATION_STATUS.PENDING,
            readyStatus: REPORT_GENERATION_STATUS.FILE_IS_READY + 1,
        };
    },
    mounted() {
        this.email = this.getUserDataProp("email");
    },
    async created() {
        let queryData = {
            project_id: this.project.project_id,
            ...this.data,
        };
        this.currentActivity = await this.downloadReport({
            project: this.project,
            queryData,
            close: this.close,
        });
        this.setEmailIfRequested();
    },
    watch: {
        getActivityLogs() {
            if (this.reportIsGenerating) {
                this.currentActivity = this.getActivityLog(
                    this.currentActivity.report_activity_log_id
                );

                if (!this.currentActivity) {
                    this.close();
                }
            }
        },
        currentActivity() {
            this.status = this.currentActivity.status + 1;
        },
    },
    methods: {
        ...mapActions(["downloadReport", "setActivityLogEmail"]),
        async validateEmail() {
            await this.$validator.validateAll();

            if (this.errors.all().length) {
                this.errors.all().forEach((el) => this.$toastr.e(el));
                return;
            }

            this.sendToLoading = true;
            this.setEmailIfRequested();
        },
        async setEmailIfRequested() {
            if (!this.sendTo && this.sendToLoading && this.reportIsGenerating) {
                this.sendToLoading = this.sendTo =
                    await this.setActivityLogEmail({
                        logId: this.currentActivity.report_activity_log_id,
                        email: this.email,
                    });
            }
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~sass/modal";

.export-progress-bar-modal {
    padding: 32px;

    .header {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: kw-color(kw-black, 7);
    }

    .body {
        margin-top: 16px;

        .export-keywords-msg {
            font-weight: 500;
            line-height: 24px;
            color: kw-color(kw-black, 1);
            margin-bottom: 7px;
        }

        /deep/ .progress-bar__used span {
            background: kw-color(kw-blue, 1) !important;
            border-radius: 12px;
            transition: width 2s linear;
        }

        .form-group,
        .export-email-send-to-msg {
            margin-top: 11px;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
        }

        .form-group {
            #set-email-button {
                width: 36px;
                height: 36px;
            }

            #email {
                width: 376px;
                height: 36px;
            }
        }

        /deep/ .kw-blue {
            color: kw-color(kw-blue, 1);
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        .close-export-progress-bar-modal {
            height: 40px;
            width: 124px;
        }
    }
}
</style>
