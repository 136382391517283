export default (i18n, toastr) => {
	return res => {
		if (!res) return;
		
		const {config} = res;

		if (config.method === 'put' && config.url.includes('/alerts/settings')) {
			toastr.s(i18n.t('saved-changes-msg'));
		}

		return res;
	};
};