// Flattens an object to a single level object with dot notation keys
// For example, { a: { b: { c: 1 } } } becomes { 'a.b.c': 1 }
export function flattenObject(obj, parentKey = '', result = {}) {
    for (let key in obj) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
            flattenObject(obj[key], newKey, result);
        } else {
            result[newKey] = obj[key];
        }
    }
    return result;
}
