export default (i18n, toastr) => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config, response } = error;

        if (!config || !response) {
            return Promise.reject(error);
        }

        const { data } = response;
        const { errors } = data;

        if (!errors) {
            return Promise.reject(error);
        }

        if (errors.length) {
            switch (config.method) {
                case 'post':
                    if (config.url.includes('/download')) {
                        errors.forEach(error => {
                            toastr.e(error.detail)
                        });
                    }
                    break;

                case 'patch':
                    if (config.url.includes('/set-recipients')) {
                        errors.forEach(error => {
                            toastr.e(error.detail)
                        });
                    }
                    break;

                case 'get':
                    if (config.url.includes('/check-status')) {
                        toastr.e(i18n.t('unexpected-error'));
                    }
                    break;
            }
        }

        return Promise.reject(error);
    };
};
